import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getStudents = async (page, session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.STUDENT.GET, { params: { page }, headers: { 'session-id': `${session_id}` } });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getFilteredStudents = async (session_id, name, id_number, program_id) => {
  try {
    const response = await axios.get(ENDPOINTS.STUDENT.GET_FILTERED_STUDENTS, {
      params: { name, id_number, program_id },
      headers: { 'session-id': `${session_id}` },
    });
    return response; // Assuming the API returns the list of users directly
  }
  catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getClassWiseStudentList = async (program_id) => {
  try {
    const session_id = localStorage.getItem('session_id');
    const response = await axios.get(ENDPOINTS.STUDENT.GET_CLASS_WISE_STUDENT_LIST, {
      params: { program_id },
      headers: { 'session-id': `${session_id}` },
    });
    return response; // Assuming the API returns the list of users directly
  }
  catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getStudentById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STUDENT.GET_STUDENT}/${id}`,
      { headers: { 'session-id': `${session_id}` } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createStudent = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: ENDPOINTS.STUDENT.CREATE,
    data: data,
    headers: { "Content-Type": "multipart/form-data" }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStudent = async (id, data) => {
  const axiosConfig = {
    method: 'post',
    url: `${ENDPOINTS.STUDENT.UPDATE}/${id}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteStudent = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.STUDENT.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStudentStatus = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.STATUS, { data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createBulkStudents = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: ENDPOINTS.STUDENT.BULK_CREATE,
    data: data,
    headers: { "Content-Type": "multipart/form-data" }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateBulkStudents = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: ENDPOINTS.STUDENT.BULK_UPDATE,
    data: data,
    headers: { "Content-Type": "multipart/form-data" }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
}

export const directUpdateStudents = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: ENDPOINTS.STUDENT.DIRECT_UPDATE,
    data: data,
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
}

export const promoteStudents = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.PROMOTE_STUDENTS, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePromotedStudents = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.MANAGED_PROMOTED_STUDENTS, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const bulkPromoteStudents = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.BULK_PROMOTE, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const generateActivationKey = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.GENRATE_ACTIVATION_KEY, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getActivatedInActivatedParentAppUserCount = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.STUDENT.PARENT_APP_ACTIVATED_INACTIVATED_USER_COUNT, {
      headers: { 'session-id': `${session_id}` },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const storeFabsDataEnrollment = async (org_code, session_name) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STUDENT.STORE_FABS_DATA_ENROLLEMENT}/${org_code}/${session_name}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getStudentDataForSync = async (org_code, session_name) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STUDENT.GET_STUDENT_DATA_FOR_SYNC}/${org_code}/${session_name}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const syncStudentData = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT.SYNC_STUDENT_DATA, { data });
    return response;
  } catch (error) {
    throw error;
  }
}
