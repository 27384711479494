export const termOptions = [
  { id: "PT-1", name: "PT-I" },
  { id: "PT-2", name: "PT-II" },
  { id: "PT-3", name: "PT-III" },
  { id: "UT-1", name: "UT-I" },
  { id: "UT-2", name: "UT-II" },
  { id: "UT-3", name: "UT-III" },
  { id: "UT-4", name: "UT-IV" },
  { id: "3", name: "Common Pre-Board" },
  { id: "4", name: "Pre-Board" },
  { id: "1", name: "Term-I" },
  { id: "2", name: "Term-II" },
  { id: "5", name: "Other" },
]

export const termOptionsMap = termOptions.reduce((acc, { id, name }) => {
  acc[id] = name;
  return acc;
}, {});


export const getTermLabel = (term) => {
  const termMapping = {
    "UT-1": "UT-I",
    "UT-2": "UT-II",
    "UT-3": "UT-III",
    "UT-4": "UT-IV",
    "PT-1": "PT-I",
    "PT-2": "PT-II",
    "PT-3": "PT-III",
    "1": "Term-I",
    "2": "Term-II",
    "3": "Common Pre-Board",
    "4": "Pre-Board",
    "5": "Other",
  };

  return termMapping[term] || null;
};



