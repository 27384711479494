import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getQueryCategories = async () => {
  try {
    const response = await axios.get(ENDPOINTS.QUERY_CATEGORY.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getQueryCategoryById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.QUERY_CATEGORY.GET_QUERY_CATEGORY}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createQueryCategory = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.QUERY_CATEGORY.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateQueryCategory = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.QUERY_CATEGORY.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteQueryCategory = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.QUERY_CATEGORY.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
