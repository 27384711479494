import React from 'react';
import { Form } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';
import ExamConfigForm from './ExamConfigForm';
import Button from '../../components/Button/Button';

const ExamForm = props => {

  return (
    <Form onSubmit={props.submitHandler} onChange={props.changeHandler}>
      <div className='row mb-2'>
        <div className="col-md-3">
          <input
            className="form-check-input"
            type="radio"
            name="studentSubjectWise"
            id="radio1"
            value={false}
            checked={props.studentSubjectWise === false}
            onChange={props.changeStudentSubjectWise}
          />
          <label className="form-check-label" htmlFor="radio1">
            Get student class wise
          </label>
        </div>
        <div className="col-md-3">
          <input
            className="form-check-input"
            type="radio"
            name="studentSubjectWise"
            id="radio2"
            value={true}
            checked={props.studentSubjectWise}
            onChange={props.changeStudentSubjectWise}
          />
          <label className="form-check-label" htmlFor="radio2">
            Get student subject wise
          </label>
        </div>
      </div>
      <ExamConfigForm
        props={props}
      />
      {/*TODO all records fetch from API and when update checkbox field already selected */}
      {/*
      <Switch
        name="studentSubjectWise"
        id="studentSubjectWise"
        checked={props.studentSubjectWise}
        onChange={props.changeStudentSubjectWise}
        label={'Get student subject wise'} />
      */}

      <div className="row mt-2">
        <div className="col-md-12">
          {
            props.data.student_loader ?
              <Loader />
              :
              <Form.Group>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>SrNo</th>
                      <th>Roll No</th>
                      <th>Admission No</th>
                      <th>Name</th>
                      <th>
                        <Switch
                          checked={(props.student_ids.length ===
                            (props.data.studentList ? props.data.studentList.length : null))
                            && props.data.studentList.length > 0 ? true : false}
                          name="all"
                          id="select-all"
                          value="1"
                          onChange={e => props.selectAllStudent(e)}
                          label={'Select All'}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="col-md-12">
                    {
                      props.data.studentList && props.data.studentList.map((student, index) =>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{student.roll_no}</td>
                          <td>{student.id_number}</td>
                          <td>{student.first_name + " " + student.last_name}</td>
                          <td>
                            <div className="form-check form-check-inline">
                              <Switch
                                name="student_id"
                                id={student.id}
                                checked={props.student_ids.includes(student.id.toString())}
                                value={student.id || ''}
                                onChange={props.inputChangeHandler}
                              />
                            </div>
                          </td>
                        </tr>)}
                    {props.data.studentList.length === 0 ?
                      <tr align="center"><th colSpan="3">No Record Found</th></tr> : null}
                  </tbody>
                </table>
              </Form.Group>}
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <Button variant="success" className='mt-2'
          type="submit">Submit</Button>
      </div>
    </Form>
  );
}

export default ExamForm;

