import React from 'react';
import Select from 'react-select';

const ExamHeaderSelect = (props) => {
  const options = props.examList.map((exam) => ({
    value: exam.id,
    label: (
      <div>
        {exam.name} <span style={{ fontWeight: '700', color: 'black' }}>
          {(exam.term == '1' || exam.term == '2') ? `(TERM-${exam.term})` : `(${exam.term})`}
        </span>
      </div>
    ),
  }));

  const handleChange = (selectedOption) => {
    props.onChangeHandler({
      target: { name: "name", value: selectedOption?.value || "" },
    });
  };
  return (
    <React.Fragment>
      <label htmlFor='name' className='mt-2'>{props.label}</label>
      <Select
        name="name"
        options={options}
        placeholder={props.placeHolder}
        className='mt-1'
        onChange={handleChange}
        value={options.find(option => option.value === props.value) || null}
      />
    </React.Fragment>
  );
};

export default ExamHeaderSelect;
