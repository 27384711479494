import React from "react";
import Button from "../../components/Button/Button";
import { FaDownload } from "react-icons/fa";

export const tableConstants = (checkOutHandler, downloadPass, downloadPassPdfFormat) => {
  let count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'Phone',
      render: rowData => {
        return <span>{rowData.phone}</span>;
      },
    },
    {
      title: 'Purpose',
      render: rowData => {
        return <span>{rowData.purpose}</span>;
      },
    },
    {
      title: 'Check In',
      render: rowData => {
        return <span>{rowData.logs && rowData.logs.check_in}</span>;
      },
    },
    {
      title: 'Check Out',
      render: rowData => {
        return <span>{rowData.logs ? rowData.logs.check_out ? rowData.logs.check_out :
          <Button onClick={() => checkOutHandler(rowData.id)}>Out</Button> : ""}</span>;
      },
    },
    {
      title: 'Actions',
      render: rowData => {
        return (
          <div className="d-flex">
            <Button onClick={() => downloadPass(rowData.id)}>View</Button> &nbsp;
            <Button variant="success" onClick={() => downloadPassPdfFormat(rowData.id)}><FaDownload /></Button>
          </div>
        );
      },
    },
  ];
  return formatedData;
};
