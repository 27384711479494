import React, { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { getProgramList, getProgramSessionMapping, programSessionMapping } from '../../services/ProgramServices';
import ProgramSessionMappingForm from './ProgramSessionMappingForm';
import Loader from '../../components/Loader/Loader';

const ProgramSessionMapping = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [programList, setProgramList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [programIds, setProgramIds] = useState([]);

  const fetchProgramList = async () => {
    try {
      setLoader(true);
      const response = await getProgramList();
      console.log('Program List:', response);
      if (response.status === 200) {
        const { data } = response.data;
        setProgramList(data);
      }
    }
    catch (error) {
      // Handle error
      return error;
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    fetchProgramList();
    fetchProgramSessionMapping();
  }, []);

  const fetchProgramSessionMapping = async () => {
    try {
      setLoader(true);
      const response = await getProgramSessionMapping();
      if (response.status === 200) {
        const data = response.data.data;
        const programIds = data.map(item => item.program.id.toString());
        setProgramIds(programIds);
      }
    } catch (error) {
      // Handle error
      return error;
    }
    finally {
      setLoader(false);
    }
  };

  const handleInputChange = (event) => {
    const progId = event.target.value;
    let updatedProgramIds;

    if (event.target.checked) {
      updatedProgramIds = [...programIds, progId];
    } else {
      updatedProgramIds = programIds.filter(id => id !== progId);
    }

    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    const updatedProgramIds = event.target.checked
      ? programList.map(program => program.id.toString())
      : [];
    setProgramIds(updatedProgramIds);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (programIds.length === 0) {
      let msg = 'Please select any class';
      enqueueSnackbar(msg, { variant: 'error' });
      return;
    }
    // Remove duplicates
    const uniqueProgramIds = [...new Set(programIds)];

    try {
      const data = { program_ids: uniqueProgramIds };

      setLoader(true);
      let response = await programSessionMapping(data);
      console.log('Response:', response);

      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
      }
    } catch (error) {
      return error;
    }
    finally {
      setLoader(false);
    }
  };

  if (!programList || programList.length === 0) {
    return (<Loader />);
  }

  const goBack = () => {
    navigate('/program/list');
  };

  return (
    <Card
      componentName={"Program Session Mapping"}
      back={goBack}>
      <ProgramSessionMappingForm
        programIds={programIds}
        programList={programList}
        loader={loader}
        onInputChange={handleInputChange}
        onSwitchChange={handleSwitchChange}
        onSubmit={handleSubmit}
      />
    </Card>
  );
}

export default ProgramSessionMapping;
