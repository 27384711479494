import axios from 'axios';
import { ENDPOINTS } from './endpoints';

// Visitor APIs
export const getVisitors = (page) => axios.get(`${ENDPOINTS.VISITOR.GET}?page=${page}`);
export const createVisitor = (data) => axios.post(`${ENDPOINTS.VISITOR.CREATE}`, data);
export const updateVisitor = (id, data) => axios.put(`${ENDPOINTS.VISITOR.UPDATE}/${id}`, data);
export const deleteVisitor = (id) => axios.delete(`${ENDPOINTS.VISITOR.DELETE}/${id}`);
export const getVisitorById = (id) => axios.get(`${ENDPOINTS.VISITOR.GET}/${id}`);
export const updateVisitorCheckOut = (id) => axios.put(`${ENDPOINTS.VISITOR.CHECK_OUT}/${id}`);

// Gate Pass APIs
export const getGatePass = (id) => axios.get(`${ENDPOINTS.VISITOR.GATE_PASS}/${id}`,
  {
    responseType: "blob", // Important for handling PDF
  }
);

export const getVisitorPass = (id) => axios.get(`${ENDPOINTS.VISITOR.PASS}/${id}`);

// Logs APIs
export const getLogs = () => axios.get(`${ENDPOINTS.VISITOR.LOG}`);
