import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getUsers = async (name, page, pageSize = 50) => {
  try {
    const session_id = localStorage.getItem('session_id');
    const response = await axios.get(`${ENDPOINTS.USER.GET}?session_id=${session_id}&name=${name}&page=${page}&page_size=${pageSize}`);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getUserById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.USER.GET_USER}/${id}`, { params: { session_id } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.USER.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.USER.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUserStatus = async (id, status) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.STATUS}/${id}`, { status });
    return response;
  } catch (error) {
    throw error;
  }
};

export const usersBulkUpload = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.USER.BULK_UPLOAD, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePassword = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.UPDATE_PASSWORD}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: `${ENDPOINTS.USER.UPLOAD_PROFILE_IMAGE}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
}

export const uploadTeacherTimeTable = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: `${ENDPOINTS.USER.UPLOAD_TEACHER_TIME_TABLE}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
};

export const update_teacher_timetable_status = async (id, status) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.UPDATE_TEACHER_TIME_TABLE_STATUS}/${id}`, { status });
    return response;
  } catch (error) {
    throw error;
  }
}

