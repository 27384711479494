import axios from 'axios';
import { ENDPOINTS } from './endpoints';
import { head } from 'lodash';

export const getStackHolderQueries = async (params) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STACK_HOLDER_QUERY.GET}`, { params });
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getStackHolderQueryById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STACK_HOLDER_QUERY.GET_QUERY}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const replyStackHolderQuery = async (data) => {
  const axiosConfig = {
    method: 'post',
    url: ENDPOINTS.STACK_HOLDER_QUERY.QUERY_REPLY,
    data: data,
    headers: { "Content-Type": "multipart/form-data" }
  };

  try {
    const response = await axios(axiosConfig)
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStackHolderQueryStatus = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.PROGRAM.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

