import React from "react";
import { Badge } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import "./Query.css";

export const tableConstants = (permissions, redirectQueryDetails, statusHandler) => {

  let formatedData = [
    {
      title: 'Profile',
      render: rowData => {
        return <img src={rowData.raised_by.profile_image} style={{ width: "20px", borderRadius: "10px" }} />;
      },
    },
    {
      title: 'Category',
      render: rowData => {
        return <span className="text-capitalize">{rowData.category && rowData.category.name}</span>;
      },
    },
    {
      title: 'Message',
      render: rowData => {
        return <span>{rowData.message.slice(0, 120)} ...</span>;
      },
    },
    {
      title: 'Request_received',
      render: rowData => {
        return <span>{rowData.created_at}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <Badge
          className={`${rowData.status === "open" ? "bg-success" : rowData.status === "in_progress" ? "bg-info" : "bg-danger"} text-uppercase`}
        >{rowData.status}</Badge>;
      },
    },
    {
      title: 'Reply',
      render: rowData => {
        return <button
          className="reply-btn"
          onClick={() => redirectQueryDetails(rowData.id)}><FaArrowLeft /> Reply</button>;
      },
    },
  ];
  return formatedData;
};
