import React, { useState, useEffect, useCallback } from 'react';
import Card from '../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import QueryCategoryForm from './QueryCategoryForm';
import { createQueryCategory, getQueryCategoryById, updateQueryCategory } from '../../services/QueryCategoryService';

const QueryCategoryManage = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const fetchQueryCategoryById = useCallback(async (id) => {
    try {
      const response = await getQueryCategoryById(id);
      if (response.status === 200) {
        const { id, name } = response.data.data;
        setId(id);
        setName(name);
      }
    }
    catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    const { id } = params;
    if (id) {
      fetchQueryCategoryById(id);
    }
  }, [params, fetchQueryCategoryById]);

  const changeHandler = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    try {
      const data = { name: name };
      setLoading(true);
      const apiRequest = id
        ? updateQueryCategory(id, {data})
        : createQueryCategory({data});

      apiRequest
        .then(response => {
          let msg = response.data.message.success;
          enqueueSnackbar(msg, { variant: 'success' });
          navigate('/query/category/list');
        })
        .catch(error => {
          handleRequestError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      handleRequestError(error);
    }
  }

  const handleRequestError = (error) => {
    enqueueSnackbar('An error occurred while processing your request.' + error, { variant: 'error' });
  };

  const back = () => {
    navigate('/organization/list');
  };

  const cardTitle = id ? 'Update QueryCategory' : 'Add QueryCategory';

  return (
    <Card componentName={cardTitle} back={back}>
      <QueryCategoryForm
        submitHandler={submitHandler}
        changeHandler={changeHandler}
        name={name}
        loading={loading}
      />
    </Card>
  );
};
export default QueryCategoryManage;
