import React from "react";
import Switch from "../../components/Switch/Switch";

export const tableConstants = (permissions, selectHandler) => {
  let count = 1;
  let formatedData = [
    {
      title: 'Sr.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Student Name',
      render: rowData => {
        return <span>{`${rowData.first_name} ${rowData.last_name}`}</span>;
      },
    },
    {
      title: 'Admission_NO',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    },
    {
      title: 'Email',
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'Class',
      render: (rowData) => {
        return <span>{rowData.program_name}</span>;
      },
    },
    {
      title: 'Gender',
      render: rowData => {
        return <span>{rowData.gender}</span>;
      },
    },
    {
      title: 'Contact_No',
      render: rowData => {
        return <span>{rowData.father_contact}</span>;
      },
    },
  ];
  return formatedData;
};
