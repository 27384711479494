import React from 'react';
import Input from "../../components/Input/Input";
import { FaChalkboardTeacher } from "react-icons/fa";

const TeacherFilter = ({ name, handleInputChange, info, setInfo, isBulk, setIsBulk,
  downloadTeacherExcel, permissions }) => {
  return (
    <div className="main-title-page page-title d-flex justify-content-space-between">
      <div className="col-md-3">
        <FaChalkboardTeacher />
        <span className="mx-2">Subject Teacher Mapping</span>
      </div>
      <div className="col-md-6">
        <Input
          type="text"
          className="form-control"
          value={name}
          placeholder="Search by name..."
          onChange={handleInputChange} // Use debounced input handler
          autoFocus
        />
      </div>
      <div className="col-md-3 mx-2">
        <button
          className="btn btn-info mx-1 text-white"
          onClick={() => setInfo(!info)}
        >
          i
        </button>
        {permissions.includes("bulk.upload.teacher.class.subject") && (
          <button
            className="btn btn-primary"
            onClick={() => setIsBulk(!isBulk)}
          >
            {!isBulk ? "Bulk Upload" : "User List"}
          </button>
        )}
        {!isBulk &&
          <button
            className="btn btn-primary mx-1"
            onClick={downloadTeacherExcel}
          >
            Download
          </button>
        }
      </div>
    </div>
  );
};

export default TeacherFilter;
