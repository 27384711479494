import React from "react";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import Select from "../../components/Select/Select";
import { Button } from "react-bootstrap";

export const tableConstants = (updateClassTeacherData, onChangeHandler, programHandler, program_id, teacher_list, permissions) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Class Teacher',
      render: rowData => {
        return <span>
          <div className={"row"}>
            <div className={`${program_id?"col-md-4":"col-md-6"}`}>
              {rowData.teacher && rowData.teacher.name}
            </div>
            {((program_id === rowData.program_id) && teacher_list) &&
              <React.Fragment>
                <div className={"col-md-6"}>
                  <Select
                    name={'teacher'}
                    id={`teacher-${rowData.id}`}
                    placeHolder={"Select Teacher"}
                    options={teacher_list && teacher_list}
                    onChangeHandler={onChangeHandler}
                    disabled={true}
                  />
                </div>
                <div className={"col-md-2"}>
                  <Button onClick={updateClassTeacherData} variant="primary">Save</Button>
                </div>
              </React.Fragment>
            }
          </div>
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program_name}</span>;
      },
    },
  ];
  if (permissions && permissions.includes("class.teacher.create")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return <span>
          {rowData.teacher && rowData.teacher.id ?
            <FaEdit
              onClick={() => programHandler(rowData)}
              color="blue"
              className="cursor-pointer" /> :
            <FaPlusCircle
              onClick={() => { programHandler(rowData) }}
              color="green"
              className="cursor-pointer" />
          }
        </span>;
      },
    });
  }
  return formatedData;
};
