import React, { useState, useEffect, useCallback } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { tableConstants } from "./StackHolderQueryTableConst";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table/Table";
import { enqueueSnackbar } from "notistack";
import { getStackHolderQueries } from "../../services/StackHolderQueryService";
import { debounce } from "lodash";
import QueryFilter from "./StackHolderQueryFilter";
import Modal from "../../components/Modal/Modal";
import StackHolderQueryForm from "./StackHolderQueryForm";
import { replyStackHolderQuery } from "../../services/StackHolderQueryService";
// import "../Query/Query.css";

const StackHolderQueryList = () => {
  const [permissions] = useState(JSON.parse(localStorage.getItem("permissions")));
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [id_number, setIdNumber] = useState("");
  const [school_name, setSchoolName] = useState("");
  const [stakeholder_email, setStakeholderEmail] = useState("");
  const [ticket_no, setTicketNo] = useState("");
  const [stakeholder_mob, setStakeholderMob] = useState("");
  const [request_type, setRequestType] = useState("");
  const [reply_status, setReplyStatus] = useState("");
  const [query_details, setQueryDetails] = useState("");
  const [reply_attachment, setReplyAttachment] = useState("");
  const [organization_id, setOrganizationId] = useState("");
  const [attchement, setAttchement] = useState("");

  const [query_id, setQueryId] = useState("");
  const [query_reply, setQueryReply] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const closeHandler = () => {
    setIsOpen(false);
    setQueryId("");
    setQueryReply("");
  };

  const searchHandler = (e) => {
    e.preventDefault();
    fetchStackHolderQueryList(1);
  };

  const fetchStackHolderQueryList = async (page) => {
    try {
      setLoader(true);
      setCurrentPage(page);

      const params = { page: page };

      if (id_number) params.id_number = id_number;
      if (school_name) params.school_name = school_name;
      if (stakeholder_email) params.stakeholder_email = stakeholder_email;
      if (ticket_no) params.id = ticket_no;
      if (stakeholder_mob) params.stakeholder_mob = stakeholder_mob;
      if (request_type) params.request_type = request_type;
      if (reply_status) params.reply_status = reply_status;

      const response = await getStackHolderQueries(params);
      setData(response.data);
      setTotalPage(response.last_page);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value, page) => {
      fetchStackHolderQueryList(page);
    }, 300), // 300ms debounce delay
    []
  );

  const handleSearchInput = (e) => {
    const { value } = e.target;
    if (value.length > 2 || value.length === 0)
      debouncedSearch(value, 1); // Call debounced function
  };

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    } else {
      fetchStackHolderQueryList(1);
    }
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "id_number") {
      setIdNumber(value);
    }
    else if (name === "school_name") {
      setSchoolName(value);
    }
    else if (name === "stakeholder_email") {
      setStakeholderEmail(value);
    }
    else if (name === "ticket_no") {
      setTicketNo(value);
    }
    else if (name === "stakeholder_mob") {
      setStakeholderMob(value);
    }
    else if (name === "request_type") {
      setRequestType(value);
    }
    else if (name === "reply_status") {
      setReplyStatus(value);
    }
    else if (name === "query_reply") {
      setQueryReply(value);
    }
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setQueryReply(value);
  };

  const handlePageChange = (i) => {
    const page = i.selected + 1;
    fetchStackHolderQueryList(page);
  }

  const redirectQueryDetails = (id) => {
    const queryDetails = data.find((item) => item.id === id);
    setQueryDetails(queryDetails.details);
    setOrganizationId(queryDetails.organization_id);
    setAttchement(queryDetails.attchement);
    setIsOpen(true);
    setQueryId(id);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("reply_attachment", reply_attachment);
    formData.append("reply_message", query_reply);
    formData.append("stakeholder_experience_id", query_id);
    formData.append("organization_id", organization_id);

    try {
      const response = await replyStackHolderQuery(formData);
      console.log("Response", response);
      if (response.status === 200) {
        setQueryId("");
        setQueryReply("");
        setIsOpen(false);
        fetchStackHolderQueryList(1);
        enqueueSnackbar(response.data.message.success, { variant: "success" });
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  }

  return (
    <div className="card">
      {loader ? (<Loader />) :
        (<div className="roleUser-list">
          <div className="card-header">
            <div className="row">
              <div className="col-md-3 main-title-page page-title">
                <FaPaperPlane /> Stackholder Queries
              </div>
            </div>
          </div>
          <div className="card-body evaluating-table">
            <Modal
              title={"Query Details"}
              show={query_id}
              isOpen={isOpen}
              onClose={closeHandler}
              onHide={() => setQueryId("")}
              size="lg"
            >
              <StackHolderQueryForm
                attchement={attchement}
                query_details={query_details}
                setReplyAttachment={setReplyAttachment}
                reply_attachment={reply_attachment}
                query_id={query_id}
                query_reply={query_reply}
                setQueryReply={setQueryReply}
                closeHandler={closeHandler}
                onChangeHandler={onChangeHandler}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
              />
            </Modal>
            <QueryFilter
              searchHandler={searchHandler}
              onChangeHandler={onChangeHandler}
            />
            {data && data.length ? (
              <Table
                cols={tableConstants(permissions, redirectQueryDetails, currentPage)}
                data={data}
                hoverable={true}
                striped={true}
              />
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center vh-10">
                <div>
                  <p className="mt-3">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        )}
      {data && data.length ? (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage - 1}
          handlePageChange={(i) => handlePageChange(i)}
        />
      ) : null}
    </div>
  );
};

export default StackHolderQueryList;
