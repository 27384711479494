import React, { useState, useEffect } from "react";
import { FaCloudSunRain } from "react-icons/fa";
import { tableConstants } from "./SyllabusTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getSyllabuses } from "../../../services/AppManagement/SyllabusService";
import TeacherViewCard from "../TeacherViewCard";
import { Link } from "react-router-dom";

const SyllabusList = () => {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchSyllabus({ selected = 0 }) {
    const session_id = localStorage.getItem('session_id');
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      const page = selected + 1;
      const response = await getSyllabuses(session_id, page);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchSyllabus({ selcted: 0 });
  }, []);

  return (
    <div className="card">
      <CardHeader
        title="Syllabus List"
        icon={<FaCloudSunRain />}
        button={''}
      />
      <div className="card-body">
        {isLoader ? <Loader /> :
          <div className="row">
            {
              data && data.map((item, index) => (
                <TeacherViewCard
                  title={"Subject: "}
                  titleValue={item.subject.name}
                  topic={<div
                    dangerouslySetInnerHTML={{
                      __html: item.syllabus_desc,
                    }}
                  />}
                  topicValue={""}
                  date={"LINK: "}
                  dateValue={<Link to={item.syllabus_docs} target="_blank">open file</Link>}
                  description={item.syllabus_desc}
                  programs={item.syllabus_programs}
                  index={index}
                  key={index}
                  colSize="4"
                  expandedIdx={[0, 1, 2]}
                />
              ))}

            {data && (
              <Pagination
                totalPage={lastPage}
                currentPage={currentPage}
                handlePageChange={(i) => fetchSyllabus(i)}
              ></Pagination>
            )}
          </div>}
      </div>
    </div>
  );
};
export default SyllabusList;
