import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaList } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";
import { getQueryCategories, deleteQueryCategory } from "../../services/QueryCategoryService";
import ListCard from "../../components/Card/ListCard";
import CardHeader from "../../components/Card/CardHeader";

const QueryCategoryList = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchQueryCategories = async () => {
    try {
      const response = await getQueryCategories();
      if (response.success) {
        setData(response.data);
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
  }

  useEffect(() => {
    fetchQueryCategories();
  }, []);

  const deleteHandler = async (id) => {
    try {
      const response = await deleteQueryCategory(id);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: "success" });
        fetchQueryCategories();
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
  };

  return (
    <div className="QueryCategory-list">
      <div className="animated">
        <div className="card">
          <CardHeader
            title="Query Categories"
            icon={<FaList />}
            permissions={permissions}
            button={permissions.includes("query.category.create") && (
              <Link color="#FFFFFF" className="btn btn-primary"
                to="/query/category/create">
                Add QueryCategory
              </Link>
            )}
          />
          <div className="card-body row">
            {data && data.map((item, idx) => (
              <ListCard
                key={idx}
                permissions={permissions}
                updatePermission="query.category.update"
                deletePermission="query.category.delete"
                updateUrl={`/query/category/update/${item.id}`}
                icon="fa-solid fa-list"
                item={item}
                onDelete={deleteHandler}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryCategoryList;
