import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Multiselect } from "multiselect-react-dropdown";
import Select from 'react-select';
import { termOptions } from '../../utils/helper';
import CustomSelect from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import { fetchEvaluationTypes } from '../../redux/evaluationType/evaluationTypeActions';
import { fetchTraits } from '../../redux/trait/traitActions';
import { getClassInChargeClasses } from '../../services/ProgramServices';
import ExamHeaderSelect from '../ExamConfiguration/ExamHeaderSelect';

const ExamConfigForm = ({ props }) => {
  const [programList, setProgramList] = useState([]);
  const session_id = localStorage.getItem('session_id');
  const role_name = JSON.parse(localStorage.getItem("roles"))[0].name;
  const dispatch = useDispatch();
  const programData = useSelector(state => state.program.programs.data);
  const evaluationType = useSelector(state => state.evaluationType.evaluationTypes.data);
  const traitData = useSelector(state => state.trait.traits.data);

  const getProgramList = async () => {
    try {
      const response = await getClassInChargeClasses(session_id);
      if (response.status === 200) {
        setProgramList(response.data.data);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }

  useEffect(() => {
    getProgramList();
    /***            Get Program   *** */
    dispatch(fetchPrograms());
    /***            Get Evalauation Type  *** */
    dispatch(fetchEvaluationTypes());
    // get trait
    dispatch(fetchTraits());
  }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-3">
          <CustomSelect
            label="Class"
            name="program_id"
            value={props.program_id}
            placeHolder={"Select Class"}
            onChangeHandler={props.changeHandler}
            options={(role_name === "SUPER ADMIN" || role_name === "SCHOOL SPOC" || role_name === "ADMIN") ? programData : programList}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <ExamHeaderSelect
            label="Assessment Name"
            name="name"
            placeHolder={"Select Exam Headers"}
            value={props.data.name}
            onChangeHandler={props.changeHandler}
            examList={props.data.examList}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Evaluation Period Start"
            type="date"
            name="start"
            value={props.data.start}
            onChangeHandler={props.changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Evaluation Period End"
            type="date"
            name="end"
            value={props.data.end}
            onChangeHandler={props.changeHandler}
            required={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          {props.data.id ?
            <CustomSelect
              label="Subject"
              name="subject_id"
              value={props.data.subject_id}
              placeHolder={"Select Subject"}
              onChangeHandler={props.changeHandler}
              options={props.subjectData}
              required={true}
            /> :
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <Multiselect
                options={props.subjectData} // Options to display in the dropdown
                onSelect={props.onSubjectSelect} // Function will trigger on select event
                selectedValues={props.selectedSubjects}
                onRemove={props.onSubjectRemove} // Function will trigger on remove event
                showCheckbox={true}
                closeOnSelect={false}
                singleSelect={props.studentSubjectWise ? true : false}
                id="program_ids"
                displayValue="name" // Property name to display in the dropdown options
              />
            </Form.Group>}
          <Form.Label htmlFor='is_active'>IsActive:&nbsp;&nbsp; </Form.Label>
          <input type="checkbox"
            onChange={props.changeHandler}
            name="is_active" id="is_active"
            value={props.data.is_active || ''}
            checked={props.data.is_active}
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Label htmlFor='is_traits'>Traits:&nbsp;&nbsp; </Form.Label>
          <input type="checkbox"
            onChange={props.changeHandler}
            name="is_traits" id="is_traits"
            value={props.data.is_traits || ''}
            checked={props.data.is_traits}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Term"
            name="term"
            value={props.data.term}
            placeHolder={"Select Term"}
            onChangeHandler={props.changeHandler}
            options={termOptions}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Evaluation Type"
            name="evaluation_type_id"
            value={props.data.evaluation_type_id}
            placeHolder={"Select Evaluation Type"}
            onChangeHandler={props.changeHandler}
            options={evaluationType}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Grade Schemes"
            name="gradeSchemeID"
            value={props.gradeSchemeID}
            placeHolder={"Select Grade Schemes"}
            onChangeHandler={props.changeHandler}
            options={props.scoreSchemesData}
            required={true}
          />
        </div>
      </div>
      <div className="row">
        {props.data.is_traits ?
          <div className="col-md-12">
            {
              props.data.traits.map((val, idx) => {
                return (
                  <div className="row mt-2" key={idx}>
                    <div className="col-md-5">
                      <Form.Group>
                        <Select
                          value={val.trait_id === undefined ? { label: 'Choose trait', value: "" } : { label: val.label, value: val.trait_id }}
                          onChange={(option) => props.changeTraitHandler({ target: { value: option.value, name: 'trait_id', label: option.label } }, idx)}
                          options={traitData && traitData.map(trait => ({ label: trait.name, value: trait.id }))}
                          placeholder="Choose Trait"
                        />
                      </Form.Group>
                    </div>
                    {props.data.traits[idx].trait_grade_scheme_type === 'Score' ?
                      <div className="col-md-5">
                        <Input
                          name={"max_score"}
                          id={"trait_max_score"}
                          placeHolder={"Max Score"}
                          value={props.data.traits[idx].max_score || ''}
                          type="number"
                          onChange={(e) => props.changeTraitHandler(e, idx)}
                          required={true}
                        />
                      </div> : null}
                    <div className="col-md-2">
                      {
                        idx === 0 ? <button onClick={props.addRow} type="button"
                          className="btn btn-primary text-center">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                          : <button className="btn btn-danger"
                            onClick={(e) => props.clickOnDelete(e, val)} >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                      }
                    </div>
                  </div>)
              })}
          </div> : null
        }
      </div>
      <div className="row">
        {props.data.attempts === "YES" ? <React.Fragment>
          <div className="col-md-3">
            <Input
              label="No of Attempts"
              type="number"
              name="total_attempts"
              value={props.data.total_attempts}
              onChangeHandler={props.changeHandler}
              required={true}
            />
          </div>
          <div className="col-md-3">
            <CustomSelect
              label="Choose Grading Method"
              name="grade_method"
              value={props.data.grade_method}
              placeHolder={"Select Graging Method"}
              onChangeHandler={props.changeHandler}
              options={props.data.gradingMethodsData}
              required={true}
            />
          </div></React.Fragment> : null}
        <div className="col-md-3">
          {props.grade_scheme_type === 'Score' ?
            <Input
              label="Max Score"
              type="number"
              name="maxScore"
              value={props.data.maxScore}
              onChangeHandler={props.changeHandler}
              required={true}
            />
            : null}
        </div>
      </div>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" id="description"
          className="form-control daterange mb-2"
          type="text" placeholder="Enter description"
          value={props.data.description || ''}
          onChange={props.changeHandler} row={3} />
      </Form.Group>
    </React.Fragment>
  );
};
export default ExamConfigForm;
