import React, { useEffect, useState } from 'react';
import { fetchPrograms } from '../../redux/program/programActions';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";
import Table from '../../components/Table/Table';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import { tableConstants } from './StudentTableConstant';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import {
  getStudents, getFilteredStudents, deleteStudent,
  updateStudentStatus, generateActivationKey, directUpdateStudents
} from '../../services/StudentService';
import CardHeader from '../../components/Card/CardHeader';
import StudentFilter from './StudentFilter';
import * as XLSX from 'xlsx';

const StudentList = () => {
  const [name, setName] = useState('');
  const [id_number, setIdNumber] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [program_id, setProgramId] = useState('');
  const [loader, setLoader] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const session_id = localStorage.getItem("session_id");
  const permissions = JSON.parse(localStorage.getItem("permissions"))
  const dispatch = useDispatch();
  const { programs } = useSelector((state) => state.program);
  const programList = programs.data;

  const searchHandler = async () => {
    try {
      setLoader(true);
      setStudentList([]);
      setIsEditMode(false);
      setIsSearch(true);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const getStudentList = async ({ selected }) => {
    let page = selected + 1;
    setIsEditMode(false);
    try {
      setLoader(true);
      const response = await getStudents(page, session_id);
      setLoader(false);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getStudentList({ selected: 0 });
    dispatch(fetchPrograms({ selected: 0 }));
  }, []);

  const studentDelete = async (id) => {
    try {
      setLoader(true);
      const response = await deleteStudent(id);
      if (response.status === 200) {
        const data = response.data;
        enqueueSnackbar(data.message.success, { variant: "success" });
        getStudentList({ selected: 0 });
      }
      setLoader(false);
    } catch (error) {
      console.error('Error deleting student:', error);
      setLoader(false);
    }
  }

  const statusHandler = async (id, status) => {
    try {
      const session_id = localStorage.getItem("session_id");
      setLoader(true);
      let data = { id, suspended: status, session_id };
      const response = await updateStudentStatus(data)
      const data1 = response.data;
      if (response.status === 200) {
        enqueueSnackbar(data1.message.success, { variant: "success" });
        if (program_id !== '')
          searchHandler();
        else
          getStudentList({ selected: 0 });
      }
    } catch (error) {
      // Handle the error here
      console.error('Error updating student status:', error);
      // You might want to do something else here, like displaying an error message to the user
    }
    finally {
      setLoader(false);
    }
  };
  const resetHandler = async () => {
    setName('');
    setIdNumber('');
    setProgramId('');
    getStudentList({ selected: 0 });
  }

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(studentList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `student-list.xlsx`);
  };

  const genrateKeyHandler = async () => {
    if (program_id === '') {
      enqueueSnackbar("Please select class", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      const studentIds = response.data.data.map(student => student.activation_key ? "" : student.id);
      const data = { student_ids: studentIds, session_id };
      const response1 = await generateActivationKey(data);
      if (response1.status === 200) {
        const data = response1.data;
        enqueueSnackbar(data.message.success, { variant: "success" });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const changeHandler = (e, id) => {
    const { name, value } = e.target;
    const updatedData = studentList.map(student => {
      if (student.id === id) {
        if (name === "dob")
          student['dob'] = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
        else
          student[name] = value;
        student.is_updated = true;
      }
      return student;
    });
    setStudentList(updatedData);
  }

  const updateBulkHandler = async () => {
    try {
      setLoader(true);
      // Ensure only bulk update logic is executed
      const student_data = studentList.filter(student => student.is_updated).map(student => {
        return {
          id: student.id,
          id_number: student.id_number,

          first_name: student.first_name,
          last_name: student.last_name,
          roll_no: student.roll_no,
          dob: student.dob,
          phone1: student.phone1,
          apaar_id: student.apaar_id,
          father_name: student.father_name,
          mother_name: student.mother_name,
          house_name: student.house_name,
          program_id: student.programs.id,
        }
      });

      const data = { data: student_data };
      let response = await directUpdateStudents(data);
      setLoader(false);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        searchHandler();
        setIsEditMode(false);
      } else {
        console.error(response.data.message.error, "error");
        enqueueSnackbar(response.data.message.error, { variant: "error" });
      }
    } catch (error) {
      setLoader(false);
      if (error.response && error.response.status === 422) {
        let errorObject = error.response.data.message.errors;
        if (errorObject) {
          Object.entries(errorObject).forEach(([key, errors]) => {
            errors.forEach((errorMsg) => {
              enqueueSnackbar(errorMsg, { variant: "error" });
              console.error(errorMsg);
            });
          });
        } else {
          console.error('Request failed:', error.message);
        }
      } else {
        console.error('Unexpected error:', error.message);
        enqueueSnackbar("An unexpected error occurred", { variant: "error" });
      }
    }
  }
  const classChangeHandler = (e) => {
    setProgramId(e.target.value);
    setIsSearch(false);
  }

  return (
    <div className='card'>
      {loader ? (
        <Loader />
      ) : (
        <div className="program-list">
          <CardHeader
            title="Students"
            icon={<FaFolderOpen />}
            button={<React.Fragment>
              <button className="btn btn-primary mx-2" onClick={downloadExcel}>
                Download Excel
              </button>
              {
                permissions.includes("student.create") &&
                <Link className="btn btn-primary" to={"/student/create"}>
                  Add Student
                </Link>
              }
            </React.Fragment>
            }
          />
          <div className="card-body">
            <StudentFilter
              programList={programList}
              name={name}
              id_number={id_number}
              program_id={program_id}
              setName={setName}
              setIdNumber={setIdNumber}
              classChangeHandler={classChangeHandler}
              searchHandler={searchHandler}
              resetHandler={resetHandler}
              genrateKeyHandler={genrateKeyHandler}
              isEditModeHandler={() => { if (program_id && isSearch) setIsEditMode(!isEditMode); else enqueueSnackbar("Select a class to filter students and enable edit mode", { variant: "info" }) }}
              isEditMode={isEditMode}
              isSearch={isSearch}
            />
            <div className='evaluating-table mt-2'>
              {studentList && studentList.length > 0 ?
                <Table
                  cols={tableConstants(permissions, statusHandler, studentDelete, isEditMode, changeHandler)}
                  data={studentList}
                  striped={true}
                  hoverable={true}
                /> : 'No data found'}
            </div>
          </div>
        </div>
      )}
      <div className="card-footer">
        {isEditMode &&
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              variant="primary"
              onClick={updateBulkHandler}
            >
              Update
            </button>
          </div>
        }
        {studentList && studentList.length ? (
          <Pagination
            totalPage={lastPage}
            handlePageChange={(i) => getStudentList(i)}
          ></Pagination>)
          : null}
      </div>
    </div>
  );
};
export default StudentList;
