import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import UserForm from "./UserForm";
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from "notistack";
import UserSample from "./UserSample.json";
import ExcelBulkUpload from "../ExcelBulkUpload/ExcelBulkUpload";
import CardHeader from "../../components/Card/CardHeader";
import { getClassWiseSubjects } from "../../services/SubjectServices";
import { createUser, getUserById, updateUser, usersBulkUpload } from "../../services/UserService";
import { getPrograms } from "../../services/ProgramServices";
import UserSubjectClassMapping from "./UserSubjectClassMapping";
import Loader from "../../components/Loader/Loader";
import { getRolePermissions } from "../../services/RolePermissionService";

const UserCreate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [state, setState] = useState({
    name: '', email: '', phone: '', password: '', status: '',
    programs: [{ id: '', subjects: [] }], subjectData: [],
    programData: [], rolesData: [], role_id: '',
    loading: false, disablesubmit: false
  });

  useEffect(() => {
    const id = params.id;
    fetchRoles();
    fetchProgram();
    fetchData(id);
  }, [params.id]);

  const fetchRoles = async () => {
    const roles = JSON.parse(localStorage.getItem('roles'));
    const org_id = localStorage.getItem('organization_id');

    const roleName = roles && roles.find((item) => Number(item.organization_id) === Number(org_id)).name;
    try {
      const rolesResponse = await getRolePermissions(1);
      if (rolesResponse.status === 200) {
        const rolesData = rolesResponse.data.data
        if (roleName && roleName.toLowerCase() === "super admin") {
          setState(prevState => ({ ...prevState, rolesData }));
        }
        else {
          const data = rolesData.filter((item) => {
            if (item.name.toLowerCase() === "super admin" || item.name.toLowerCase() === "admin")
              return false;
            else
              return true;
          });
          setState(prevState => ({ ...prevState, rolesData: data }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchProgram = async () => {
    try {
      const programsResponse = await getPrograms();
      if (programsResponse.success) {
        const programData = programsResponse.data;
        setState(prevState => ({ ...prevState, programData }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchData = async (id) => {
    if (!id) return;
    try {
      setLoader(true);
      const session_id = localStorage.getItem('session_id');
      const userResponse = await getUserById(id, session_id);
      if (userResponse.status !== 200) return;

      const { user, role, programs } = userResponse.data.data;
      const { name, email, phone, password } = user;
      const roleId = role ? role.role_id : "";

      if (programs) {
        const updatedPrograms = await Promise.all(
          programs.map(async (item) => {
            const subjects = await getSubject(item.id);
            return {
              id: item.id,
              subject_list: subjects,
              subjects: item.subjects.map(val => val),
            };
          })
        );

        const programsToAdd = [...updatedPrograms];
        for (let i = programsToAdd.length; i < updatedPrograms.length; i++) {
          programsToAdd.push({ id: '', subjects: [] });
        }
        setState(prevState => ({
          ...prevState,
          programs: programsToAdd,
          teacherData: updatedPrograms,
          selectedSubject: programs
        }));
      }

      setState(prevState => ({
        ...prevState,
        name,
        email,
        phone,
        password,
        role_id: roleId,
        loading: false,
      }));
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...state.programs];
    list[index][name] = value;
    getSubject(e.target.value);
  };

  const subjectChangeHandler = (e, id) => {
    const { programs } = state;
    programs[id] = { ...programs[id], subjects: e.map(val => val) };
    setState(prevState => ({
      ...prevState,
      programs
    }));
  };

  const getSubject = async (programID) => {
    try {
      const response = await getClassWiseSubjects(programID);
      const subjectData = response.data.data;
      setState(prevState => ({
        ...prevState,
        subjectData
      }));
      return subjectData;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const onSubjectRemove = (event, programId) => {
    setState(prevState => {
      const updatedPrograms = prevState.programs.map((program) => {
        if (program.id === programId) {
          return { ...program, subjects: event };
        }
        return program;
      });
      return { ...prevState, programs: updatedPrograms };
    });
  };

  const addNewRow = () => {
    setState(prevState => ({
      ...prevState,
      programs: [...prevState.programs, { id: '', subjects: [] }],
    }));
  };

  const clickOnDelete = (record) => {
    setState((prevState) => ({
      ...prevState,
      programs: state.programs.filter(r => r !== record)
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedPrograms = state.programs.map((item) => ({
      id: item.id,
      subjects: item.subjects.map(subItem => subItem.id),
    }));

    const session_id = localStorage.getItem('session_id');
    const { name, email, phone, password, role_id } = state;
    const data = { name, email, phone, password, role_id, programs: formattedPrograms, session_id };

    try {
      setLoader(true);
      const response = params.id
        ? await updateUser(params.id, { data })
        : await createUser({ data });

      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        navigate('/user/list');
      }
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
    finally {
      setLoader(false);
    }
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(UserSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `user-sample.xlsx`);
  }

  const excelFileReader = (e) => {
    const session_id = localStorage.getItem('session_id');
    let organization_id = localStorage.getItem('organization_id');

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const result = {};
      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });

      // Now 'result' contains JSON data for each sheet
      let final_data = result['Sheet1'];
      let roleNameIdMap = [];
      state.rolesData.forEach((item) => {
        let key = item.name.toLowerCase().replace(/\s/g, '');
        roleNameIdMap[key] = item.id
      })

      // Now 'result' contains JSON data for each sheet
      let finalData = final_data && final_data.map((item) => {
        let roleName = item.role.toLowerCase().replace(/\s/g, '');
        let role_id = roleNameIdMap[roleName];
        let { name, email, phone, password
        } = item;

        return {
          name, email, phone, role_id, password, session_id, organization_id
        };
      });
      setFile(finalData);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const userBulkUpload = async () => {
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select a file", { variant: "error" });
      return;
    }
    else {
      let data = { file };
      try {
        setLoader(true);
        const response = await usersBulkUpload(data);
        if (response && response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          navigate('/user/list');
        }
        else {
          enqueueSnackbar(response.data.message.errors, { variant: "error" });
        }
      } catch (error) {
        return error;
      }
      finally {
        setLoader(false);
      }
    }
  }

  const back = () => {
    navigate('/user/list');
  }
  // Rest of your component logic...

  return (
    <div className="content">
      <div className="card">
        <CardHeader
          title={params.id ? "Update User" : "Create User"}
          icon={''}
          button={
            <div className="">
              {permissions.includes("bulk.upload.user") ?
                <React.Fragment>
                  {params.id ? "" :
                    <Button variant="primary" className="mx-2" onClick={() => setIsBulk(!isBulk)}>
                      {!isBulk ? "Bulk Upload" : "Manual Create"}
                    </Button>
                  }
                </React.Fragment> : ""}
              <Button variant="secondary" onClick={back}>
                Back
              </Button>
            </div>
          }
        />
        {loader ? <Loader /> :
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              {isBulk ?
                <ExcelBulkUpload
                  excelFileReader={excelFileReader}
                  bulkUploadFile={userBulkUpload}
                  downloadExcel={downloadExcel}
                />
                :
                <div>
                  <UserForm
                    state={state}
                    inputHandler={inputHandler}
                    params={params}
                  />
                  <UserSubjectClassMapping
                    handleChange={handleChange}
                    subjectChangeHandler={subjectChangeHandler}
                    onSubjectRemove={onSubjectRemove}
                    addNewRow={addNewRow}
                    clickOnDelete={clickOnDelete}
                    state={state}
                  />
                </div>
              }
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-end">
                <div className="">
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        }
      </div>
    </div>
  );
};

export default UserCreate;

