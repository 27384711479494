import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchTeachers } from "../../../redux/teacher/teacherActions";
import { fetchSubjects } from "../../../redux/subject/subjectActions";
import { fetchPrograms } from '../../../redux/program/programActions';
import Loader from "../../../components/Loader/Loader";
import { FaChalkboardTeacher } from "react-icons/fa";
import { tableConstants } from "./AttendanceMapTableConst";
import Table from "../../../components/Table/Table";
import Pagination from "../../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import Modal from "../../../components/Modal/Modal";
import AttendanceTeacherMapForm from './AttendanceTeacherMapForm';
import { getMappedPrograms, createAttendanceTeacherMapping } from '../../../services/AppManagement/AttendanceService';
import Input from "../../../components/Input/Input";
import { debounce } from 'lodash'; // Import debounce from lodash
import { getUsers } from "../../../services/UserService";

const AttendanceTeacherMap = () => {
  const [state, setState] = useState({
    new_password: "",
    resetPasswordUserId: null,
    permissions: JSON.parse(localStorage.getItem("permissions")),
    name: "",
    isBulk: false,
    file: null
  });

  const dispatch = useDispatch();
  const { programData } = useSelector(state => ({
    programData: state.program.programs.data ?? [],
    subjectData: state.subject.subjects.data ?? []
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [program_ids, setProgramIds] = useState([]);
  const [leave_approval_program_ids, setLeaveApprovalProgramIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [teacherData, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const approverProgramHandler = (e) => {
    const value = e.target.value;
    let updatedProgramIds;
    if (e.target.checked)
      updatedProgramIds = [...leave_approval_program_ids, value];
    else
      updatedProgramIds = leave_approval_program_ids.filter(id => id !== value);
    setLeaveApprovalProgramIds(updatedProgramIds);
  };

  const selectAllApproverProgramHandler = (e) => {
    const updatedProgramIds = e.target.checked
      ? programData.map(program => program.id.toString())
      : [];
    setLeaveApprovalProgramIds(updatedProgramIds);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    let updatedProgramIds;
    if (event.target.checked)
      updatedProgramIds = [...program_ids, value];
    else
      updatedProgramIds = program_ids.filter(id => id !== value);
    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    const updatedProgramIds = event.target.checked
      ? programData.map(program => program.id.toString())
      : [];
    setProgramIds(updatedProgramIds);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      program_ids: program_ids,
      session_id: localStorage.getItem('session_id'),
      teacher_id: id,
      leave_approval_program_ids: leave_approval_program_ids
    };
    try {
      const response = await createAttendanceTeacherMapping(data);
      if (response.status === 200 || response.status === 201) {
        setProgramIds([]);
        setId(null);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        closeModal();
        dispatch(fetchTeachers(state.name, { selected: currentPage }));
      }
    } catch (error) {
      console.log("API Error:", error.response);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
  };

  const fetchMappedPrograms = async (id) => {
    try {
      setProgramIds([]);
      setLeaveApprovalProgramIds([]);
      setLoading(true);
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let programs = response.data.data.attendances.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        const programIds = programs.map(item => item.id.toString());
        setProgramIds(programIds);
        let approverPrograms = response.data.data.leave_approver.map((item) => item.program.id.toString());
        setLeaveApprovalProgramIds(approverPrograms);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setLoading(false);
    }
  };

  const showModel = (rowData) => {
    setId(rowData.id);
    fetchMappedPrograms(rowData.id);
    openModal(true);
  }

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    }
    fetchUserList(state.name, 1);
    dispatch(fetchPrograms({ selected: 0 }));
    dispatch(fetchSubjects({ selected: 0 }));
  }, []);

  const fetchUserList = async (name, page) => {
    try {
      setLoader(true);
      setCurrentPage(page);
      const response = await getUsers(name, page);
      if (response.success) {
        setData(response.data);
        setTotalPage(response.last_page);
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  const getTeacherList = (name, { selected }) => {
    let page = selected + 1;
    setCurrentPage(page);
    fetchUserList(name, page);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchUserList(value, 1);
    }, 300),
    []
  );

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setState(prevState => ({ ...prevState, name: value }));
    if (value.length > 2 || value.length === 0)
      debouncedSearch(value); // Call debounced function
  };

  const { permissions, name } = state;

  return (
    <React.Fragment>
      {loader ?
        <Loader /> : (
          <div className="card">
            <div className="card-header">
              <div className="">
                <div className="main-title-page page-title d-flex justify-content-space-between">
                  <div className="">
                    <FaChalkboardTeacher />
                    <span className="mx-2">Attendance Teacher Mapping</span>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Search by name..."
                      onChangeHandler={handleSearchInput} // Use the debounced input handler
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body evaluating-table">
              <Modal isOpen={isModalOpen} onClose={closeModal} w={"1000px"}>
                <AttendanceTeacherMapForm
                  submitHandler={submitHandler}
                  onInputChange={handleInputChange}
                  onSwitchChange={handleSwitchChange}
                  programData={programData}
                  program_ids={program_ids}
                  loader={loading}
                  onApproverProgramHandler={approverProgramHandler}
                  onSelectAllApproverProgramHandler={selectAllApproverProgramHandler}
                  leave_approval_program_ids={leave_approval_program_ids}
                />
              </Modal>
              {}
              {teacherData && teacherData.length ? (
                <div>
                  <Table
                    cols={tableConstants(permissions, showModel, currentPage)}
                    data={teacherData}
                    hoverable={true}
                    striped={true}
                  />
                  <Pagination
                    totalPage={totalPage}
                    currentPage={currentPage - 1}
                    handlePageChange={(i) => getTeacherList(name, i)}
                  ></Pagination>
                </div>
              ) : null}
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default AttendanceTeacherMap;
