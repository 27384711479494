import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from "notistack";
import CardHeader from "../../components/Card/CardHeader";
import { getUserById, uploadProfileImage } from "../../services/UserService";
import Loader from "../../components/Loader/Loader";
import ProfileView from "./ProfileView";

const UserProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    name: '', email: '', phone: '', password: '', status: '',
    programs: [{ id: '', subjects: [] }], subjectData: [],
    rolesData: [], role_id: '', loading: false
  });

  useEffect(() => {
    const id = params.id;
    fetchData(id);
  }, [params.id]);

  const fetchData = async (id) => {
    if (!id) return;
    try {
      setLoader(true);
      const session_id = localStorage.getItem('session_id');
      const userResponse = await getUserById(id, session_id);
      if (userResponse.status !== 200) return;

      const { user, role_details, programs } = userResponse.data.data;
      const { name, email, phone, password, profile_image, timetable_path } = user;

      if (programs) {
        const updatedPrograms = await Promise.all(
          programs.map(async (item) => {
            return {
              class: { id: item.id, name: item.name },
              subjects: item.subjects.map(val => val),
            };
          })
        );

        const programsToAdd = [...updatedPrograms];
        for (let i = programsToAdd.length; i < updatedPrograms.length; i++) {
          programsToAdd.push({ id: '', subjects: [] });
        }
        setState(prevState => ({
          ...prevState,
          programs: programsToAdd,
          teacherData: updatedPrograms,
          selectedSubject: programs
        }));
      }

      setState(prevState => ({
        ...prevState,
        name, email, phone, password, profile_image,
        timetable_path, role_details, loading: false,
      }));
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);
      const response = '';
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        navigate('/user/list');
      }
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
    finally {
      setLoader(false);
    }
  };

  const uploadProfileImageHandler = async (e) => {
    // Handle profile image upload logic here
    const user_id = params.id;
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('profile_image', e.target.files[0]);
      formData.append('user_id', user_id);
      const response = await uploadProfileImage(formData)
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        fetchData(user_id);
      }
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar("Error uploading profile image", { variant: "error" });
    }
    finally {
      setLoader(false);
    }
  }

  return (
    <div className="content">
      <div className="card">
        <CardHeader
          title={"User Profile"}
          icon={''}
          button={''}
        />
        {loader ? <Loader /> :
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <ProfileView
                user={state}
                uploadProfileImageHandler={uploadProfileImageHandler}
              />
            </div>
          </form>
        }
      </div>
    </div>
  );
};

export default UserProfile;

