import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
export const tableConstants = (permissions, showModel, currentPage) => {
  let count = 1;
  {console.log("Current Page:", currentPage)}
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{(50 * (currentPage-1)) + count++}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'Email',
      render: rowData => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {rowData.is_attendance_mapping ?
            <FontAwesomeIcon
              icon={faEdit}
              color="#321fdb"
              style={{
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => showModel(rowData)}
            /> :
            <FontAwesomeIcon
              icon={faPlusCircle}
              color="#321fdb"
              style={{
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => showModel(rowData)}
            />
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
