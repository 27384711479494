import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FaBars, FaLock, FaSignOutAlt, FaUser } from "react-icons/fa";
import Organization from "../pages/Organization/Organization";
import Session from "../pages/Session/Session";

const TheHeader = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const roles = JSON.parse(localStorage.getItem("roles"));
  const roleName = roles ? roles[0].name : '';
  const userID = user ? user.id : '';
  const userNames = user ? user.name : '';
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');
    window.location.reload();
    navigate('/login');
  };

  const toggleSidebar = () => {
    document.body.classList.toggle("open", !flag);
    setFlag(!flag);
  };

  return (
    <React.Fragment>
      <div className="header-upper row">
        <div className="col-10 left">
          <FaBars size={'20px'} cursor={'pointer'} color={'rgba(0, 0, 21, 0.5)'}
            onClick={toggleSidebar} className="" />
        </div>
        <div className="col-2 right">
          <Navbar bg="none" expand="md" className="navbar-main">
            <Navbar.Collapse>
              <Nav className="navbar-nav-toggle-header" color="yellow">
                <NavDropdown title={`Hi, ${userNames}`} id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to={`/user/profile/${userID}`}>
                    <span className="mr-2"><FaUser /> </span>Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`/password/update/${userID}`}>
                    <span className="mr-2"><FaLock /> </span>Update Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>
                    <span className="mr-2"><FaSignOutAlt /> </span>Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      <div className={`row px-2 py-1 ${roleName === "STACKHOLDER FEEDBACK" ? "mb-4" : ""}`}>
        {roleName !== "STACKHOLDER FEEDBACK" && (
          <React.Fragment>
            <div className="col-md-3 pb-mb-2">
              <Organization getOrganisation={props.getOrganisation} />
            </div>
            <div className="col-md-2">
              <Session organisation={props.org_id} />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default TheHeader;
