import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { tableConstants } from "./UserTableConstant";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table/Table";
import { enqueueSnackbar } from "notistack";
import { updateUserStatus, getUsers } from "../../services/UserService";
import { debounce, set } from "lodash"; // Import debounce from lodash
import Input from "../../components/Input/Input";

const TeacherList = () => {
  const [name, setName] = useState("");
  const [permissions] = useState(JSON.parse(localStorage.getItem("permissions")));
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchUserList = async (name, page) => {
    try {
      setLoader(true);
      setCurrentPage(page);
      const response = await getUsers(name, page);
      if (response.success) {
        setData(response.data);
        setTotalPage(response.last_page);
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value, page) => {
      fetchUserList(value, page);
    }, 300), // 300ms debounce delay
    []
  );

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length > 2 || value.length === 0)
      debouncedSearch(value, 1); // Call debounced function
  };

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    } else {
      fetchUserList(name, 1);
    }
  }, []);

  const statusHandler = async (id, status) => {
    try {
      const response = await updateUserStatus(id, status);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        fetchUserList(name, 0);
      } else {
        enqueueSnackbar(response.data.message.error, { variant: "error" });
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (i) => {
    const page = i.selected + 1;
    fetchUserList(name, page);
  }

  return (
    <div className="card">
      {loader ? (<Loader />) :
        (<div className="roleUser-list">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaUser />
                </div>
                <span>User List</span>
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  name={"name"}
                  id="name"
                  value={name}
                  placeholder="Search by name..."
                  onChange={handleSearchInput} // Use the debounced input handler
                  autoFocus
                />
              </div>
              {permissions.includes("users.create") && (
                <Link className="btn btn-primary" to="/user/create">
                  Add User
                </Link>
              )}
            </div>
          </div>
          <div className="card-body evaluating-table">
            {data && data.length ? (
              <Table
                cols={tableConstants(permissions, statusHandler, currentPage)}
                data={data}
                hoverable={true}
                striped={true}
              />
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center vh-10">
                <div>
                  <p className="mt-3">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        )}
      {data && data.length ? (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage-1}
          handlePageChange={(i) => handlePageChange(i)}
        />
      ) : null}
    </div>
  );
};

export default TeacherList;
