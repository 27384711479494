import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getQueries = async () => {
  try {
    const response = await axios.get(ENDPOINTS.QUERY.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getQueryById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.QUERY.GET_QUERY}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const replyQuery = async (data, query_id) => {
  try {
    const response = await axios.post(`${ENDPOINTS.QUERY.QUERY_REPLY}/${query_id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateQueryStatus = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.PROGRAM.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProgram = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.PROGRAM.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClassInChargeClasses = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.PROGRAM.GET_CLASS_IN_CHARGE_CLASSES,
      { headers: { 'session-id': session_id } });
    return response;
  } catch (error) {
    throw error;
  }
}
