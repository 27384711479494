import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { createVisitor } from '../../services/VisitorSevice';
import Card from '../../components/Card/Card';
import Loader from '../../components/Loader/Loader';
import VisitorFrom from './VisitorForm';
import { useNavigate, useParams } from 'react-router-dom';

const VisitorManage = () => {
  const [visitor, setVisitor] = useState({
    name: '',
    phone: '',
    purpose: '',
    meeting_with: '',
    category_id: ''
  });
const [loader, setLoader] = useState(false);
const navigate = useNavigate();
const { id } = useParams();

  const handleChange = (e) => {
    setVisitor({ ...visitor, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { ...visitor };
      const response = await createVisitor({ data });
      if (response.status === 200) {
        enqueueSnackbar('Visitor created successfully', { variant: 'success' });
        navigate('/visitors');
      }
    } catch (error) {
      console.error('Error creating visitor:', error);
    }
  };

  const back = () => {
    navigate('/visitors');
  }

  return (
    <Card componentName={id ? 'Update Visitor' : 'Add Visitor'} back={back}>
      {loader ? <Loader /> :
        <VisitorFrom
          name={visitor.name}
          phone={visitor.phone}
          category_id={visitor.category_id}
          purpose={visitor.purpose}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      }
    </Card>
  );
};

export default VisitorManage;
