import React from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { getTermLabel } from "../../utils/helper";

export const tableConstants = (permissions, page) => {
  let count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{(50 * (page-1)) + count++}</span>;
      },
    },
    {
      title: 'Exam Header',
      render: rowData => {
        return <span>{rowData.name ? rowData.name.name : ''}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
         {getTermLabel(rowData.term)}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },

    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },

    {
      title: 'Evaluation',
      render: rowData => {
        return <span>{rowData.evaluation_type.name}</span>;
      },
    },

    {
      title: 'Start',
      render: rowData => {
        return <span>{rowData.start}</span>;
      },
    },
    {
      title: 'End',
      render: rowData => {
        return <span>{rowData.end}</span>;
      },
    },
    {
      title: 'Student Graded',
      render: rowData => {
        return <span>{rowData.total_mark_participant}/
          {rowData.total_participant}</span>;
      },
    },
    {
      title: 'Max Score',
      render: rowData => {
        return <span>{rowData.max_score}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <React.Fragment>
          {rowData.total_mark_participant == 0 ? (
            <span style={{ color: "red" }}> Not Graded</span>
          ) : (rowData.total_mark_participant > 0 && rowData.total_mark_participant != rowData.total_participant) ? (
            <span style={{ color: 'orange' }} >In Progress</span>
          ) : (
            <span style={{ color: 'green' }}>Graded</span>
          )}
        </React.Fragment>;
      },
    },
  ];

  if (permissions.includes("assessment.marking.view")) {
    formatedData.push({
      title: 'Marking',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("assessment.marking.view") &&
              <Link to={"/assessment/participants/list/" + rowData.id}>
                  <FontAwesomeIcon icon={faEye} color="blue" />
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
