
import React, { useState, useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getCirculars } from "../../../services/AppManagement/CircularService";
import TeacherViewCard from "../TeacherViewCard";
import { Link } from "react-router-dom";

const TeacherViewCircularList = () => {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchCircular({ selected = 0 }) {
    const session_id = localStorage.getItem("session_id");
    const page = selected + 1;
    setCurrentPage(selected);
    try {
      setIsLoader(true);
      const response = await getCirculars(session_id, page);
      setIsLoader(false);
      if (response.status === 200) {
        setData(response.data.data);
        setLastPage(response.data.last_page);
      }
    } catch (error) {
      setIsLoader(false);
      console.error("API Error:", error.response);
    }
  }

  useEffect(() => {
    fetchCircular({ selected: 0 });
  }, []);

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="Circular List"
          icon={<FaCircle />}
          button={''}
        />
        <div className="card-body">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="row">
              {data && data.map((item, index) => (
                <TeacherViewCard
                  title={`CIRCULAR NO:`}
                  titleValue={item.circular_no}
                  topic={item.circular_topic}
                  topicValue={''}
                  date={""}
                  dateValue={item.circular_file &&<Link to={item.circular_file} target={"_blank"}>OPEN LINK</Link>}
                  description={item.circular_desc}
                  programs={item.circular_programs}
                  index={index}
                  key={index}
                />
              ))}
              {data && (
                <Pagination
                  totalPage={lastPage}
                  currentPage={currentPage}
                  handlePageChange={(i) => fetchCircular(i)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherViewCircularList;
