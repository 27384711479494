import React, { useState, useEffect } from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getEventCalendars } from "../../../services/AppManagement/EventCalendarService";
import TeacherViewCard from "../TeacherViewCard";

const TeacherViewEventList = () => {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  async function fetchEvent({ selected }) {
    try {
      setIsLoader(true);
      let page = selected + 1;
      const session_id = localStorage.getItem('session_id');
      const response = await getEventCalendars(session_id, page);
      if (response.status === 200) {
        setCurrentPage(page - 1);
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchEvent({ selected: 0 });
  }, []);

  return (
    <div className="card">
      <CardHeader
        title="Event"
        icon={<FaChalkboardTeacher />}
        button={''}
      />
      <div className="card-body">
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && data.map((item, index) => (
              <TeacherViewCard
                title={item.title}
                titleValue={''}
                topic={`Is_Holiday: `}
                topicValue={item.is_holiday === "1" ? "YES" : "NO"}
                date={`DATE: `}
                dateValue={item.event_date}
                description={item.description}
                programs={item.event_programs}
                key={index}
                index={index}
              />
            ))}

            <Pagination
              totalPage={lastPage}
              currentPage={currentPage}
              handlePageChange={(i) => fetchEvent(i)}
            ></Pagination>
          </div>
        }
      </div>
    </div>
  );
};
export default TeacherViewEventList;
