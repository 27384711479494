import React from 'react';

const CardHeader = ({ title, icon, button }) => {
  return (
    <div className="card-header">
      <div className="d-flex justify-content-between align-items-center">
        <div className="main-title-page page-title d-flex align-items-center">
          <div className="mx-2 mb-1">
            {icon}
          </div>
          <span className='text-uppercase'>{title}</span>
        </div>
        <div className="add-btn">
          {button}
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
