import React, { useState, useEffect } from "react";
import { FaGoodreads } from "react-icons/fa";
import { tableConstants } from "./WordOfTheDayTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getWordOfTheDays } from "../../../services/AppManagement/WordOfTheDayService";
import TeacherViewCard from "../TeacherViewCard";

const TeacherViewWordOfTheDayList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  async function fetchWordOfTheDay({ selected = 0 }) {
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      let page = selected + 1;
      const response = await getWordOfTheDays(page);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchWordOfTheDay({ selected: 0 });
  }, []);

  return (
    <div className="card">
      <CardHeader
        title="Word of The Day"
        icon={<FaGoodreads />}
        button={''}
      />
      <div className="card-body">
        {isLoader ? <Loader /> :
          <div className="row">
            {data && data.map((item, index) => (
              <TeacherViewCard
                // title={item.title}
                titleValue={item.title}
                topic={item.quote}
                // topicValue={item.quote}
                date={" Date: "}
                dateValue={item.word_date}
                description={item.description}
                index={index}
                colSize="6"
                expandedIdx={[0, 1]}
              />
            ))}

            {data && (
              <Pagination
                totalPage={lastPage}
                currentPage={currentPage}
                handlePageChange={(i) => fetchWordOfTheDay(i)}
              ></Pagination>
            )}
          </div>}
      </div>
    </div>
  );
};
export default TeacherViewWordOfTheDayList;
