import React from "react";
import { faEdit, faKey, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash, FaFileUpload } from "react-icons/fa";
import Input from "../../components/Input/Input";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Switch from '../../components/Switch/Switch';

export const tableConstants = (resetPasswordHandler, resetPasswordStatus, resetPasswordChangeHandler,
  uploadHandler, statusTimetableHandler, resetPasswordUserId, permissions, page) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{(page - 1) * 50 + count++}</span>;
      },
    },
    {
      title: 'name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'email',
      render: rowData => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'role',
      render: rowData => {
        return <span>{rowData.role ? rowData.role : ""}</span>;
      },
    },
    {
      title: 'TimeTable',
      render: rowData => {
        return <span>
          {/* Hidden File Input */}
          <Input
            label={
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-upload-time-table`}>
                    {"Upload Timetable"}
                  </Tooltip>
                }>
                <FaFileUpload color="blue"
                  cursor={"pointer"} size={"20px"}
                />
              </OverlayTrigger>
            }
            type="file"
            name={`timetable_${rowData.id}`}
            id={`timetable_${rowData.id}`}
            onChange={(e) => uploadHandler(e, rowData.id)} // Pass event and row ID to handler
            style={{ display: "none" }}
          />
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-top`}>
                {"View Timetable"}
              </Tooltip>
            }>
            {!rowData.timetable_path ?
              <FaEyeSlash style={{ color: "blue", fontSize: "20px", cursor: "pointer", marginLeft: "10px" }} /> :
              <FaEye style={{ color: "blue", fontSize: "20px", cursor: "pointer", marginLeft: "10px" }}
                onClick={() => window.open(rowData.timetable_path, "_blank")}
              />}
          </OverlayTrigger>
        </span>;
      },
    },
    {
      title: 'Reset Password',
      render: rowData => {
        return (
          <Form>
            {
              resetPasswordUserId === rowData.id ?
                <React.Fragment>
                  <Form.Group>
                    <input name="new_password"
                      id="new_password"
                      className="form-control"
                      placeholder="Reset Password"
                      type="password"
                      onChange={(e) => resetPasswordChangeHandler(e)} />
                  </Form.Group>
                  <Button variant="primary" className="mt-1"
                    onClick={e => resetPasswordHandler(e, rowData.id)}>Reset</Button>
                </React.Fragment>
                : <FontAwesomeIcon icon={faKey}
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => resetPasswordStatus(rowData.id)}>
                </FontAwesomeIcon>
            }
          </Form>
        );
      }
    },
    {
      title: 'Status',
      render: rowData => {
        return (
          <React.Fragment>
            <span>
              <Switch
                checked={Number(rowData.timetable_status) === 1 ? true : false}
                id={`custom-switch-${rowData.id}`}
                onChange={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to change the timetable view status?"
                    )
                  )
                    statusTimetableHandler(rowData.id, Number(rowData.timetable_status) === 1 ? 0 : 1)
                }}
              /></span>
          </React.Fragment>
        );
      },
    }
  ];

  if (permissions.includes("teacher.subject.mapping.create") || permissions.includes("teacher.subject.mapping.update")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("publish.result.update") &&
              <React.Fragment>
                {rowData.is_mapping ? (
                  <Link to={`/teacher/mapping/update/${rowData.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                ) : (
                  <Link to={`/teacher/mapping/create/${rowData.id}`}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </Link>
                )}
              </React.Fragment>
            }
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
