import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEdit, FaSearch } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import AssessmentFilter from "../AssessmentMarking/AssessmentFilter";
import './Exam.css';
import { getProgramWiseSubjectList, getProgramWiseExamList, bulkExamConfigCreate } from "../../services/ExamService";
import Switch from "../../components/Switch/Switch";
import Form from "react-bootstrap/Form";
import { enqueueSnackbar } from "notistack";
import Pagination from "../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import { getExams, getFilteredExams } from "../../services/ExamService";

const ExamConfigBulkCreate = () => {
  const [subjectData, setSubjectData] = useState([]);
  const [exam_ids, setExamIds] = useState([]);
  const [idx, setIdx] = useState(null);
  const [examList, setExamList] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [program_id, setProgramID] = useState('');
  const [subject_id, setSubjectID] = useState('');
  const [evaluation_type_id, setEvaluationTypeID] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [name, setName] = useState('');
  const [term, setTerm] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [loading, setIsLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [last_page, setLastPage] = useState(1);
  const [globalDate, setGlobalDate] = useState(false);
  const [globalStartDate, setGlobalStartDate] = useState('');
  const [globalEndDate, setGlobalEndDate] = useState('');

  const org_id = localStorage.getItem("organization_id");
  const session_id = localStorage.getItem("session_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const [exam, setExam] = useState([]);
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    if (!org_id || !session_id) return;
    dispatch(fetchPrograms());
    dispatch(fetchSubjects({ selected: 0 }));
    dispatch(fetchEvaluationTypes());
    getExamConfigList({ selected: 0 });
  }, [dispatch]);

  const getExamConfigList = async ({ selected }) => {
    let current_page = selected + 1;
    try {
      setIsLoader(true);
      // const response = await getExamConfigs(current_page);
      const response = await getExams(session_id, current_page);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = response.data.last_page;
        setEventData(eventData);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setSubjectData(subjectData);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleSearch = useCallback(async ({ selected }) => {
    const page = selected + 1;
    try {
      setSearch(true);
      setIsLoader(true);
      setCurrentPage(page);
      // const response = await getFilteredExamConfigs(name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const { data, last_page } = response.data;
        setEventData(data);
        setLastPage(last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }, [program_id, subject_id, evaluation_type_id, name, start, end, term, status, session_id])

  // <--- Make sure the dependencies are correctly listed
  const handleReset = (event) => {
    event.preventDefault();
    setProgramID('');
    setSubjectID('');
    setName('');
    setEvaluationTypeID('');
    setStart('');
    setEnd('');
    setTerm('');
    setStatus('');
    getExamConfigList({ selected: 0 });
  };

  const getExamList = async (program_id) => {
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        console.log(" examList ", examList);
        setExamList(examList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setAdvancedSearch(!advancedSearch);
  };

  const handleInputChange = (event, incomingExam) => {
    const { value, checked } = event.target;
    const exam_id = value.toString();
    let traits_data = [];
    if (incomingExam.is_traits) {
      traits_data = incomingExam.traits && incomingExam.traits.map((trait) => ({
        trait_id: trait.subject_trait.id,
        trait_position: trait.trait_position,
        max_score: trait.max_score,
        grade_scheme_id: trait.grade_scheme.id,
      }));
    }

    let obj = {
      id: exam_id, name: incomingExam.name.id, term: incomingExam.term,
      session_id: session_id, program_id: incomingExam.program.id, subject_id: incomingExam.subject.id,
      evaluation_type_id: incomingExam.evaluation_type.id, max_score: incomingExam.max_score,
      grade_scheme_id: incomingExam.grade_scheme.id, status: 1, is_active: incomingExam.is_active,
      total_attempts: incomingExam.total_attempts, start: incomingExam.start, end: incomingExam.end,
      is_traits: incomingExam.is_traits, attempts: incomingExam.attempts, description: incomingExam.description,
      grade_method: incomingExam.grade_method, traits: traits_data
    };

    if (checked) {
      setExamIds([...exam_ids, exam_id]);
      setExam([...exam, obj]);
    } else {
      const updatedStudentIds = exam_ids.filter((id) => id !== exam_id);
      setExamIds(updatedStudentIds);
      const updatedExam = exam.filter((item) => item.id !== exam_id);
      setExam(updatedExam);
    }
  };

  const selectAllExam = (event) => {
    const exam_ids = [];
    const examData = [];
    if (event.target.checked) {
      eventData.forEach((exam) => {
        const id = exam.id.toString();
        exam_ids.push(id);
        let traits_data = [];
        if (exam.is_traits) {
          traits_data = exam.traits && exam.traits.map((trait) => ({
            trait_id: trait.subject_trait.id,
            trait_position: trait.trait_position,
            max_score: trait.max_score,
            grade_scheme_id: trait.grade_scheme.id,
          }));
        }
        let obj = {
          id: id, name: exam.name.id, term: exam.term,
          session_id: session_id, program_id: exam.program.id, subject_id: exam.subject.id,
          evaluation_type_id: exam.evaluation_type.id, max_score: exam.max_score,
          grade_scheme_id: exam.grade_scheme.id, status: 1, is_active: exam.is_active,
          total_attempts: exam.total_attempts, start: exam.start, end: exam.end, grade_method: exam.grade_method,
          is_traits: exam.is_traits, attempts: exam.attempts, description: exam.description, traits: traits_data
        };
        examData.push(obj);
      });
    } else {
      eventData.forEach((student) => {
        const id = student.id;
      });
    }
    setExam(examData);
    setExamIds(exam_ids);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setExamList([]);
      setSubjectData([]);
      setProgramID(value);
      getExamList(value);
      getSubjectList(value);
      // setSearch(true); // Set search flag to true when program_id changes
    }
    else if (name === 'subject_id') {
      setSubjectID(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'evaluation_type_id') {
      setEvaluationTypeID(value);
    }
    else if (name === 'start') {
      setStart(value);
    }
    else if (name === 'end') {
      setEnd(value);
    }
    else if (name === 'term') {
      setTerm(value);
    }
    else if (name === 'status') {
      setStatus(value);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (exam_ids.length === 0) {
      enqueueSnackbar("Please select at least one exam", { variant: "error" });
      return;
    }
    try {
      let examInput;
      if (globalStartDate !== '' && globalEndDate !== '' && globalDate) {
        examInput = exam.map((exam) => ({
          ...exam,
          start: globalStartDate,
          end: globalEndDate
        }));
      }
      else {
        examInput = exam;
      }
      const response = await bulkExamConfigCreate(examInput);
      if (response.status === 200) {
        enqueueSnackbar("Exam Configuration created successfully", { variant: "success" });
        setExam([]);
        navigate("/exam/config/list");
      }
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to create exam", { variant: "error" });
    };
  }
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const updatedExam = [...eventData];
    updatedExam[index][name] = value;
    setExam(updatedExam);
  };

  return (
    <div className="card">
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div className="page-title d-flex align-items-center">
                <Button className="btn-success" onClick={advancedSearchHandler}>
                  <FaSearch /> Search
                </Button>
              </div>
              <Link className="btn btn-primary" to="/exam/config/list">Back</Link>
            </div>
          </div>

          <div className="card-body">
            <div className={`filter-event ${advancedSearch ? 'show' : 'hide'} mb-5`}
              id="advanced-search">
              <AssessmentFilter
                evaluationType={evaluationType}
                programData={programData}
                subjectData={subjectData}
                examList={examList}
                subject_id={subject_id}
                program_id={program_id}
                evaluation_type_id={evaluation_type_id}
                name={name}
                start={start}
                end={end}
                term={term}
                status={status}
                onChangeHandler={changeHandler}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </div>
            <Form onSubmit={submitHandler}>
              {eventData && eventData.length > 0 ? (
                <div className="evaluating-table event-tablecus">
                  <div className="row mt-2">
                    <div className="col-md-12">
                      {
                        loading ?
                          <Loader />
                          :
                          <Form.Group>
                            <table className="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th>SrNo</th>
                                  <th>Name</th>
                                  <th>Term</th>
                                  <th>Class</th>
                                  <th>Subject</th>
                                  <th>EvaluationType</th>
                                  <th>Max Score</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>
                                    <Switch
                                      checked={(exam_ids.length ===
                                        (eventData ? eventData.length : null))
                                        && eventData.length > 0 ? true : false}
                                      name="all"
                                      id="select-all"
                                      value="1"
                                      onChange={e => selectAllExam(e)}
                                      label={'Select All'}
                                    />
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="col-md-12">
                                {
                                  eventData && eventData.map((exam, index) =>
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{exam.name.name}</td>
                                      <td>
                                        {exam.term === "UT-1" ? "UT-I" : null}
                                        {exam.term === "UT-2" ? "UT-II" : null}
                                        {exam.term === "UT-3" ? "UT-III" : null}
                                        {exam.term === "PT-1" ? "PT-I" : null}
                                        {exam.term === "PT-2" ? "PT-II" : null}
                                        {exam.term === "PT-3" ? "PT-III" : null}
                                        {exam.term === "1" ? "Term-I" : null}
                                        {exam.term === "3" ? "Common Pre-Board" : null}
                                        {exam.term === "4" ? "Pre-Board" : null}
                                        {exam.term === "2" ? "Term-II" : null}
                                      </td>
                                      <td>{exam.program.name}</td>
                                      <td>{exam.subject.name}</td>
                                      <td>{exam.evaluation_type.name}</td>
                                      <td>{idx === index ?
                                        <Input
                                          type="number"
                                          name="max_score"
                                          value={exam.max_score}
                                          onChangeHandler={(e) => inputChangeHandler(e, index)}
                                        />
                                        : exam.max_score}
                                      </td>
                                      <td>
                                        {idx === index ?
                                          <Input
                                            type="date"
                                            name="start"
                                            value={exam.start}
                                            onChangeHandler={(e) => inputChangeHandler(e, index)}
                                          /> : exam.start}</td>
                                      <td>
                                        {idx === index ?
                                          <Input
                                            type="date"
                                            name="end"
                                            value={exam.end}
                                            onChangeHandler={(e) => inputChangeHandler(e, index)}
                                          /> : exam.end}
                                      </td>
                                      <td>
                                        <div className="form-check form-check-inline">
                                          {eventData.length &&
                                            <Switch
                                              name="exam_id"
                                              id={exam.id}
                                              checked={exam_ids.includes(exam.id.toString())}
                                              value={exam.id}
                                              onChange={e => handleInputChange(e, exam)}
                                            />
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <FaEdit onClick={() => setIdx(index)} color="blue" className="cursor-pointer" />
                                      </td>
                                    </tr>)}
                                {eventData.length === 0 ?
                                  <tr align="center"><th colSpan="3">No Record Found</th></tr> : null}
                              </tbody>
                            </table>
                          </Form.Group>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center"> No data found</div>
              )}
              <div className="d-flex justify-content-between">
                <div className="">
                  <Button type="button" className="btn btn-primary"
                    onClick={() => setGlobalDate(!globalDate)}>
                    SET DATE</Button>
                </div>
                {
                  globalDate && <React.Fragment>
                    <div className="col-md-3">
                      <Input type="date" name={'globalStartDate'}
                        value={globalStartDate}
                        onChange={(e) => setGlobalStartDate(e.target.value)} />
                    </div>
                    <div className="col-md-3 mx-2">
                      <Input type="date" name={'globalEndDate'}
                        value={globalEndDate}
                        onChange={(e) => setGlobalEndDate(e.target.value)} />
                    </div>
                  </React.Fragment>
                }
                <div className="">
                  <Button type="submit" className="btn btn-primary" onClick={submitHandler}>
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={last_page}
            currentPage={current_page - 1}
            handlePageChange={(i) => search ? handleSearch(i) : getExamConfigList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default ExamConfigBulkCreate;
