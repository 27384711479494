import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import QueryReplyForm from './QueryReplyForm';
import { getQueryById, replyQuery } from '../../services/QueryService';
import { Button } from 'react-bootstrap';

const QueryManage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [reply, setReply] = useState('');
  const [query, setQuery] = useState({});
  const [parent_id, setParentId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [query_id, setQueryId] = useState(null);

  useEffect(() => {
    if (params.id) {
      setParentId(params.id);
      getUniqueQuery();
    }
    dispatch(fetchPrograms());
  }, [params.id, dispatch]);

  async function getUniqueQuery() {
    setLoader(true);
    try {
      const response = await getQueryById(params.id);
      if (response.status === 200) {
        const data = response.data.data;
        setQuery(data);
        setReply("");
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  }

  const handleChange = (event) => {
    const { value } = event.target;
    setReply(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(false);

    const data = {
      reply,
      query_id: query_id,
      parent_id: parent_id,
    };
    console.log("DATA", data);

    try {
      const response = await replyQuery(data, query_id);
      if (response.status === 201) {
        setQueryId(null);
        setReply("");
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate('/queries');
      }
    } catch (error) {
      console.error(error);
      setLoader(true);
    }
  };

  const back = () => {
    navigate('/queries');
  };

  const queryHandler = (id) => {
    if (id) setQueryId(id);
  }

  return (
    <div >
      {loader ? <Loader /> :
        <React.Fragment>
          <div className="d-flex justify-content-end">
            <Button variant="light" onClick={back}>Back</Button>
          </div>
          <QueryReplyForm
            key="test"
            reply={reply}
            query={query}
            query_id={query_id}
            queryHandler={queryHandler}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          {query && query.children &&
            <React.Fragment>
              {query.children.map((child, index) => (
                <QueryReplyForm
                  key={index}
                  reply={reply}
                  query={child ? child : query}
                  query_id={query_id}
                  queryHandler={queryHandler}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ))
              }
            </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default QueryManage;
