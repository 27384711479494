import React from 'react';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';

const StudentFilter = ({
  programList, name, id_number, program_id, isSearch,
  setName, setIdNumber, classChangeHandler, searchHandler, resetHandler,
  generateKeyHandler, type, isEditModeHandler, isEditMode
}) => {
  return (
    <div className="row">
      <div className="col-md-3">
        <Select
          name="program_id"
          value={program_id}
          onChangeHandler={classChangeHandler}
          options={programList}
          placeHolder="Select Class"
        />
      </div>
      <div className="col-md-3">
        <Input
          type="text"
          value={name}
          placeholder="Search by first name..."
          onChangeHandler={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="col-md-3">
        <Input
          type="text"
          value={id_number}
          placeholder="Search by Admission..."
          onChangeHandler={(e) => {
            setIdNumber(e.target.value)
          }}
        />
      </div>
      <div className="col-md-3">
        <Button
          variant="success"
          className='mx-1'
          onClick={searchHandler}>
          Search
        </Button>
        <Button variant="secondary"
          className='mx-1'
          onClick={resetHandler}>
          Reset
        </Button>
        {type === "activation" ?
          <Button variant="secondary"
            onClick={generateKeyHandler}>
            Generate
          </Button> :
          <React.Fragment>
            {isSearch && program_id &&
              <Button variant={isEditMode ? "primary" : "secondary"}
                className='mx-1'
                onClick={isEditModeHandler}>
                {isEditMode ? "Edit Mode On" : "Edit Mode Off"}
              </Button>
            }
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default StudentFilter;
