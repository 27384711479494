import React, { useEffect, useState } from "react";
import { getVisitorPass } from "../../services/VisitorSevice";
import "./VisitorPass.css";

const VisitorPass = ({ visitorId }) => {
  const [data, setdata] = useState({ visitor: {}, gatePass: {}, QrCode: {} });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVisitorPassHandler(visitorId);
  }, [visitorId]);

  const getVisitorPassHandler = async (id) => {
    try {
      const response = await getVisitorPass(id);
      if (response.status === 200) {
        setdata(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  // Print only the Visitor Pass div
  const handlePrint = () => {
    const printContent = document.getElementById("print").innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload to restore original content
  };

  if (loading) return <p>Loading visitor pass...</p>;

  return (
    <div className="d-flex justify-content-center mb-2 row" id="print">

      <h2>Visitor Pass</h2>

      <div className="details">
        <div className="pass-details">
          <p><strong>Name:</strong> {data.visitor && data.visitor.name}</p>
          <p><strong>Phone:</strong> {data.visitor && data.visitor.phone}</p>
          <p><strong>Meeting With:</strong> {data.visitor && data.visitor.meeting_with}</p>
          <p><strong>Purpose:</strong> {data.visitor && data.visitor.purpose}</p>
          <p><strong>Pass Number:</strong> {data.visitor && data.gatePass.pass_number}</p>
          <p><strong>Issued At:</strong> {data.visitor && data.visitor.issued_at}</p>
        </div>

        <div className="qr-code">
          <img src={`${data.qrCode}`} alt="Gate Pass QR Code" />
        </div>
      </div>

      {/* Print Button */}
      <div className="d-flex justify-content-end">
        <button className="print-button" onClick={handlePrint}>Print Pass</button>
      </div>
    </div>
  );
};

export default VisitorPass;
