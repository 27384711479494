import React from 'react';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

const QueryFilter = (
  {
    id_number,
    school_name,
    stakeholder_email,
    ticket_no,
    stakeholder_mob,
    request_type,
    reply_status,
    searchHandler,
    onChangeHandler
  }
) => {
  return (
    <React.Fragment>
      <div className='row mb-3'>
        <div className="col-md-3">
          <Input
            type="text"
            name="id_number"
            id="id_number"
            value={id_number}
            placeholder={"Admission No"}
            onChangeHandler={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="text"
            name="school_name"
            id="school_name"
            placeholder={"School Name"}
            value={school_name}
            onChangeHandler={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="text"
            name="stakeholder_email"
            id="stakeholder_email"
            placeholder={"Email"}
            value={stakeholder_email}
            onChangeHandler={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="col-md-3">
          <Input
            type="text"
            name="ticket_no"
            id="ticket_no"
            placeHolder={"Ticket No"}
            value={ticket_no}
            onChangeHandler={(e) => onChangeHandler(e)}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className="col-md-3">
          <Input
            type="number"
            name="stakeholder_mob"
            id="stakeholder_mob"
            placeholder={"Mobile No"}
            value={stakeholder_mob}
            onChangeHandler={(e) => onChangeHandler(e)}
          />
        </div>
        <div className="col-md-3">
          <Select
            name="request_type"
            id="request_type"
            value={request_type}
            onChangeHandler={(e) => onChangeHandler(e)}
            options={[
              { id: "", name: "Select Request Type" },
              { id: "0", name: "Open" },
              { id: "1", name: "Closed" },
            ]}
          />
        </div>
        <div className="col-md-3">
          <Select
            name="reply_status"
            id="reply_status"
            value={reply_status}
            onChangeHandler={(e) => onChangeHandler(e)}
            options={[
              { id: "", name: "Select Status" },
              { id: "0", name: "Open" },
              { id: "1", name: "Closed" },
            ]}
          />
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            onClick={searchHandler}>
            Search
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QueryFilter;
