import React, { useState } from "react";
import { FaEdit, FaChevronDown, FaChevronUp } from "react-icons/fa";
import UserProfileImg from "../../assets/image/userProfile.png";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const ProfileView = ({ user, uploadProfileImageHandler }) => {
  const [expandedClassIndex, setExpandedClassIndex] = useState(0); // First item open by default

  const toggleAccordion = (index) => {
    setExpandedClassIndex(expandedClassIndex === index ? null : index);
  };

  const timeTableOpenHandler = () => {
    if (user.timetable_path)
      window.open(user.timetable_path, "_blank");
    else
       enqueueSnackbar("TimeTable not uploaded", { variant: "error" });
  }

  return (
    <div style={styles.container}>
      {/* Left Side: User Information */}
      <div style={styles.userInfoContainer}>
        <div style={styles.imageContainer}>
          <img
            src={user.profile_image || UserProfileImg}
            alt={`${user.name}'s avatar`}
            style={styles.avatar}
          />
          <label htmlFor="profileImageUpload" style={styles.editButton}>
            <FaEdit />
          </label>
          <Input
            id="profileImageUpload"
            type="file"
            name="profile_image"
            style={styles.hiddenInput}
            onChange={uploadProfileImageHandler}
          />
        </div>
        <h1 style={styles.name}>{user.name}</h1>
        <p style={styles.field}>
          <strong>Email:</strong> {user.email}
        </p>
        <p style={styles.field}>
          <strong>Designation:</strong> {user.role_details ? user.role_details.name : ""}
        </p>
        <p style={styles.field}>
          <strong>Phone:</strong> {user.phone}
        </p>
        <p style={styles.field}>
          <Link
            className="btn btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={timeTableOpenHandler}>
            View TimeTable
          </Link>
        </p>
      </div>

      {/* Right Side: Class and Subject Information */}
      <div style={styles.classesContainer}>
        <h2 style={styles.classesHeading} className="text-uppercase">Classes and Subjects</h2>
        {user.programs && user.programs[0].id &&
          user.programs.map((cls, index) => (
            <div key={index} style={styles.classItem}>
              {/* Accordion Header */}
              <div
                style={styles.accordionHeader}
                onClick={() => toggleAccordion(index)}
              >
                <h3 style={styles.className}>{cls.class ? cls.class.name : ""}</h3>
                {expandedClassIndex === index ? <FaChevronUp /> : <FaChevronDown />}
              </div>

              {/* Accordion Content with Slide-Down Effect */}
              <div
                style={{
                  ...styles.accordionContent,
                  maxHeight: expandedClassIndex === index ? "300px" : "0",
                }}
              >
                <ul style={styles.subjectList}>
                  {cls.subjects.map((subject, idx) => (
                    <li key={idx} style={styles.subjectItem}>
                      {subject.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileView;

// Styling
const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "20px",
    backgroundColor: "#f7f9fc",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
  },
  userInfoContainer: {
    flex: "1",
    marginRight: "20px",
    textAlign: "center",
  },
  imageContainer: {
    position: "relative",
    display: "inline-block",
    marginBottom: "20px",
  },
  avatar: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    border: "4px solid var(--clr-icon)",
  },
  editButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "var(--clr-icon)",
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    padding: "8px",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  hiddenInput: {
    display: "none",
  },
  name: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
  field: {
    fontSize: "16px",
    color: "#555",
    margin: "10px 0",
  },
  classesContainer: {
    flex: "2",
    marginLeft: "20px",
    textAlign: "left",
  },
  classesHeading: {
    fontSize: "20px",
    color: "var(--clr-icon)",
    marginBottom: "15px",
    borderBottom: "2px solid var(--clr-icon)",
    paddingBottom: "5px",
  },
  classItem: {
    marginBottom: "15px",
    background: "#fff",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
  },
  accordionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  className: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  },
  accordionContent: {
    overflow: "hidden",
    transition: "max-height 0.3s ease-out",
  },
  subjectList: {
    listStyleType: "none",
    padding: "10px 0",
    margin: "0",
  },
  subjectItem: {
    fontSize: "14px",
    color: "#555",
    padding: "5px 0",
    borderBottom: "1px solid #eee",
  },
};

