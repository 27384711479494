import React from "react";
import { Badge } from "react-bootstrap";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
// import "./Query.css";

export const tableConstants = (permissions, redirectQueryDetails, page) => {
  var count = 1;
  let formatedData = [
    {
      title: 'SrNo.',
      render: rowData => {
        return <span>{(50 * (page - 1)) + count++}</span>;
      },
    },
    {
      title: 'Admission_No',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    },
    {
      title: 'Student_Name',
      render: rowData => {
        return <span className="text-capitalize">{rowData.student_name && rowData.student_name}</span>;
      },
    },
    {
      title: 'class',
      render: rowData => {
        return <span className="text-capitalize">{rowData.student_class && rowData.student_class}</span>;
      },
    },
    {
      title: 'Request_Type',
      render: rowData => {
        return <span className="text-capitalize">{rowData.request_type && rowData.request_type}</span>;
      },
    },
    {
      title: 'School',
      render: rowData => {
        return <span className="text-capitalize">{rowData.school_name && rowData.school_name}</span>;
      },
    },
    {
      title: 'Message',
      render: rowData => {
        return <span>{rowData.details && rowData.details.slice(0, 120)} ...</span>;
      },
    },
    {
      title: 'father_name',
      render: rowData => {
        return <span>{rowData.stakeholder_name}</span>;
      },
    },
    {
      title: 'father_email',
      render: rowData => {
        return <span>{rowData.stakeholder_email}</span>;
      },
    },
    {
      title: 'father_phone',
      render: rowData => {
        return <span>{rowData.stakeholder_mob}</span>;
      },
    },
    {
      title: 'Attachment',
      render: rowData => {
        return <span>{rowData.attchement ? <Link onClick={() => window.open(rowData.attchement)}><FaEye /></Link> : <FaEyeSlash style={{ color: "blue" }} />}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <Badge
          className={`${rowData.reply_status === 0 ? "bg-success" : "bg-danger"} text-uppercase`}
        >{rowData.reply_status === 0 ? "OPEN" : "CLOSED"}</Badge>;
      },
    },
    {
      title: 'Request_received',
      render: rowData => {
        return <span>{rowData.created_at}</span>;
      },
    },
  ];
  if (permissions && permissions.includes("stakeholder.query.reply")) {
    formatedData.push(
      {
        title: 'Reply',
        render: rowData => {
          return <button
            className="reply-btn"
            onClick={() => redirectQueryDetails(rowData.id)}><FaArrowLeft /> Reply</button>;
        },
      },
    );
  }
  return formatedData;
};
