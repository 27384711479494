import React from "react";
import { faEdit, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Input from "../../components/Input/Input";
import moment from 'moment';
import DummyImage from "../../assets/image/profile_image.png";

export const tableConstants = (permissions, statusHandler, studentDelete, isEditMode, changeHandler) => {
  let count = 1;
  let formatedData = [
    {
      title: 'Sr',
      render: (rowData) => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>{count++}</span>;
      },
    },
    // {
    //   title: 'Image',
    //   render: (rowData) => {
    //     return <span>
    //       <img src={rowData.profile_image ? rowData.profile_image : DummyImage} alt="student"
    //         style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
    //     </span>;
    //   },
    // },
    {
      title: 'RollNo',
      render: (rowData) => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="roll_no"
              style={{ minWidth: "70px" }}
              value={rowData.roll_no}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.roll_no}
        </span>;
      },
    },
    {
      title: 'Admission_NO',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>{rowData.id_number}</span>;
      },
    },
    {
      title: 'First_Name',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="first_name"
              style={{ minWidth: "150px" }}
              value={rowData.first_name}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.first_name}
        </span>;
      },
    },
    {
      title: 'Last_Name',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="last_name"
              style={{ minWidth: "150px" }}
              value={rowData.last_name}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.last_name}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00", width: "150px !important" }}>{rowData.programs && rowData.programs.name}</span>;
      },
    },
    {
      title: 'Date_of_Birth',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="date"
              name="dob"
              style={{ minWidth: "100px" }}
              value={moment(rowData.dob, "DD-MM-YYYY").format("YYYY-MM-DD")}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.dob}
        </span>;
      },
    },
    {
      title: 'Mother_Name',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="mother_name"
              style={{ minWidth: "200px" }}
              value={rowData.mother_name}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.mother_name}
        </span>;
      },
    },
    {
      title: 'Father_Name',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="father_name"
              style={{ minWidth: "200px" }}
              value={rowData.father_name}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.father_name}
        </span>;
      },
    },
    {
      title: 'Apaar_ID',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="apaar_id"
              style={{ minWidth: "200px" }}
              value={rowData.apaar_id}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.apaar_id}
        </span>;
      },
    }, ,
    {
      title: 'ParentApp',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>{`${rowData.is_activated == '1' ? "ACTIVATED" : "INACTIVATED"}`}</span>;
      },
    },
    {
      title: 'Contact_No',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="phone1"
              style={{ minWidth: "150px" }}
              value={rowData.phone1}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.phone1}
        </span>;
      },
    },
    {
      title: 'House_Name',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>
          {isEditMode ?
            <Input
              type="text"
              name="house_name"
              style={{ minWidth: "120px" }}
              value={rowData.house_name}
              onChange={(e) => changeHandler(e, rowData.id)}
            /> : rowData.house_name}
        </span>;
      },
    },
    {
      title: 'ActivationKey',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>{rowData.activation_key}</span>;
      },
    },
    {
      title: 'OTP',
      render: rowData => {
        return <span style={{ color: Number(rowData.suspended) === 0 ? "" : "#f00" }}>{rowData.otp ? rowData.otp : "-"}</span>;
      },
    }
  ];

  if (permissions.includes("student.update")) {
    formatedData.push({
      title: 'Operation',
      render: rowData => {
        return (
          <span>
            {permissions.includes("student.update") && <React.Fragment>
              {(rowData.suspended) === 0 &&
                <Link to={`/student/update/${rowData.id}`}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="#321fdb"
                    style={{ cursor: "pointer" }}
                  />
                </Link>}
            </React.Fragment>}
            &nbsp;&nbsp;
            {permissions.includes("student.update") &&
              <FontAwesomeIcon
                icon={Number(rowData.suspended) === 0 ? faEye : faEyeSlash}
                style={{ color: Number(rowData.suspended) === 0 ? "#321fdb" : "#f00" }}
                className="cursor-pointer"
                onClick={() => {
                  if (window.confirm(`Are you sure you wish to ${rowData.suspended === 0 ? "InActive" : "Active"} the student?`))
                    statusHandler(rowData.id, rowData.suspended === 0 ? 1 : 0)
                }}
              />
            }
            &nbsp;&nbsp;
            {permissions.includes("student.delete") &&
              <span
                onClick={() => {
                  if (window.confirm("Are you sure you wish to delete this item?"))
                    studentDelete(rowData.id);
                }}>
                <i className="fa fa-trash text-danger"
                  style={{ cursor: 'pointer' }}></i>
              </span>
            }
          </span>
        );
      },
    });
  }
  return formatedData;
};
