import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getClassTeachers = async (page) => {
  try {
    const response = await axios.get(ENDPOINTS.CLASS_TEACHER.GET);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFilteredExams = async (session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_FILTERED}`, {
      params: {
        session_id: session_id,
        query: name,
        program_id: program_id,
        subject_id: subject_id,
        evaluation_type_id: evaluation_type_id,
        start: start,
        end: end,
        term: term,
        status: status,
        page: page
      }
    });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getExamById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM.GET_EXAM}/${id}`, {
      headers: {
        "session-id": session_id
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createClassTeacher = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.CLASS_TEACHER.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateClassTeacher = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.CLASS_TEACHER.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteClassTeacher = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.CLASS_TEACHER.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
