import React from 'react';

const ExamConfigurationGuideline = () => {
  return (
    <div className="info-box">
      <h3>How to Use</h3>
      <ol>
        <li>Go to the Bulk Upload Button</li>
        <li>Download the <a href="/template.xlsx">template</a>.</li>
        <li>Fill in fields like Teacher Email, Class, Subject</li>
        <li>Ensure data format matches the template guidelines.</li>
        <li>Upload the file and click "Submit".</li>
      </ol>
      <p><strong>Note:</strong> File must be in .xlsx format and under 5MB.</p>
      <p>For further help, contact adminstrator or <a href="mailto:ets+app@teams.apeejay.edu">ets+app@teams.apeejay.edu</a>.</p>
    </div>
  );
};

export default ExamConfigurationGuideline;
