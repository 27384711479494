

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../Query/Query.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Input from '../../components/Input/Input';
import { Link } from 'react-router-dom';

const StackHolderQueryForm = ({ attchement, query_details, reply_attachment,setReplyAttachment, query_id, query_reply, handleSubmit, handleChange, }) => {
  // Track the open state of replies
  const [openReply, setOpenReply] = useState(0); // Default open the first reply

  // Toggle the reply open/close state
  const toggleReply = (index) => {
    setOpenReply(openReply === index ? -1 : index); // If same index clicked, close it
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between'>
        <span className='query-type text-capitalize'>Ticket No. {query_id}</span>
      </div>
      <div className="query-container" style={{ backgroundColor: "rgb(238 232 232)" }}>
        <div className='query-message'>
          <span>{query_details}</span>
        </div>
      </div>
      <span>{attchement ? <Link onClick={() => window.open(attchement)}>View Attachment: <FaEye /></Link> :
      ""}</span>
      <Form onSubmit={handleSubmit}>
        <div className="row mt-4">
          <div className="col-md-12 mt-2">
            <Input
              type="file"
              name="reply_attachment"
              id="reply_attachment"
              placeHolder={"Reply Attachment"}
              onChangeHandler={(e) => setReplyAttachment(e.target.files[0])}
              accept="image/*,application/pdf"
              multiple={false}
            />
          </div>
          <div className="col-md-12 mt-2">
            <Form.Group>
              <Form.Label>Reply</Form.Label>
            </Form.Group>
            <CKEditor
              editor={ClassicEditor}
              data={query_reply}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleChange({ target: { name: 'query_reply', value: data } });
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button variant="secondary" type="submit">Send</Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default StackHolderQueryForm;
