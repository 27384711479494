import React, { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { FaFolderOpen } from 'react-icons/fa';
import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import { tableConstants } from './SyncDataTableConstant';
import { Button } from 'react-bootstrap';
import { storeFabsDataEnrollment, getStudentDataForSync, syncStudentData } from '../../services/StudentService';

const SyncDataWithDataLake = () => {
  const [dataLakeStudents, setDataLakeStudents] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  const selectHandler = (e, student) => {
    if (e.target.checked) {
      setStudentList([...studentList, student]);
    } else {
      setStudentList(studentList.filter((id) => id !== student));
    }
  };

  const getDatafromFabsStoreIntoEvs = async () => {
    const session_name = localStorage.getItem('session_name');
    const org_code = localStorage.getItem('org_code');

    try {
      const response = await storeFabsDataEnrollment(org_code, session_name); // Ensure it's awaited
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        return true; // Indicate success
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log(error);
    }
    return false; // Indicate failure
  };

  const getSyncStudents = async () => {
    setLoader(true);
    const session_name = localStorage.getItem('session_name');
    const org_code = localStorage.getItem('org_code');

    const isDataStored = await getDatafromFabsStoreIntoEvs(); // Wait for completion

    if (isDataStored && org_code && session_name) {
      try {
        const response = await getStudentDataForSync(org_code, session_name); // Ensure it's awaited
        if (response.status === 200) {
          setDataLakeStudents(response.data.data);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        console.log(error);
      }
    }

    setLoader(false);
  };

  useEffect(() => {
    getSyncStudents();
  }, []);

  const enrollHandler = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const response = await syncStudentData(dataLakeStudents);
      if (response.status === 200) {
        getSyncStudents();
        setStudentList([]);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  };

  return (
    <div className='card'>
      {loader ? (
        <Loader />
      ) : (
        <div className="program-list">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaFolderOpen />
                </div>
                <span>Students</span>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Table
              cols={tableConstants(permissions, selectHandler)}
              data={dataLakeStudents}
              hoverable={true}
              striped={true}
            />
            <div className="d-flex justify-content-end mt-2">
              <Button variant="primary" onClick={enrollHandler}>Enroll</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncDataWithDataLake;
