import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { enqueueSnackbar } from "notistack";
import { downloadReportCard } from "../../services/ReportCardService";
import Switch from "../../components/Switch/Switch";
import { FaDownload } from "react-icons/fa";

export function StudentRow({ student, index, term, selectStudentHandler,
  selectedStudentIds, resultSuccessData, reportCard }) {
  const previewResult = async (student, term) => {
    let student_id = student.id;
    let session_id = localStorage.getItem("session_id");

    try {
      enqueueSnackbar('Please wait, it will take time to download the complete results', { variant: 'info' });
      console.log('student_id', student_id);
      const response = await downloadReportCard(student_id, session_id, term, { responseType: 'arraybuffer' });
      console.log('response pre', response);
      if (response.status === 200) {
        enqueueSnackbar('Result downloaded successfully', { variant: 'success' });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // Open the PDF in a new tab
        window.open(url, '_blank');
      }
    } catch (error) {
      enqueueSnackbar('Exam Marking Not Yet Completed!', { variant: 'error' });
      console.error(error);
    }
  };

  const downloadResult = async (student, term) => {
    let student_id = student.id;
    let name = student.first_name;
    let id_number = student.id_number;
    let session_id = localStorage.getItem("session_id");
    try {
      enqueueSnackbar('Please wait, it will take time to download the complete results', { variant: 'info' });
      const response = await downloadReportCard(student_id, session_id, term, { responseType: 'arraybuffer' });
      if (response.status === 200) {
        enqueueSnackbar('Result downloaded successfully', { variant: 'success' });
        const blob = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = blob;
        link.setAttribute('download', `${name}-${id_number}-term-${term}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      enqueueSnackbar('Exam Marking Not Yet Completed!', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{student.roll_no}</td>
      <td>{student.id_number}</td>
      <td>{`${student.first_name} ${student.last_name}`}
      </td>
      <td>{student.programs && student.programs.name}</td>
      <td>
        <span style={{ cursor: 'pointer' }}
          onClick={() => previewResult(student, term)}>
          <FontAwesomeIcon icon={faEye} color="blue" />
        </span>
      {/*  <span>
          <FaDownload style={{ cursor: 'pointer' }}
            onClick={() => reportCard(student)} />
        </span>*/}
      </td>
      <td>
        <Switch
          name="student"
          id={student.id}
          value={student.id}
          checked={selectedStudentIds.includes(student.id.toString())}
          onChange={(e) => selectStudentHandler(e)}
        />
      </td>
      <td>
        <span style={{ cursor: 'pointer' }}
          onClick={() => downloadResult(student, term)}>
          <FontAwesomeIcon icon={faDownload} color="blue" />
        </span>
        {/*resultSuccessData && resultSuccessData.map((data) => {
          if (data.student_id === student.id) {
            return <span style={{ color: "green" }}>  Result Downloaded successfully</span>
          }
        })*/}
      </td>
    </tr>
  );
}
