import React from "react";
import {
  FaHome, FaClock, FaBookOpen, FaClipboardList, FaUniversity,
  FaFolder, FaTachometerAlt, FaListAlt, FaCalendarAlt, FaChalkboardTeacher,
  FaUser, FaUserCog, FaGraduationCap, FaCheckCircle, FaMarker, FaLaptop, FaDribbble,
  FaBook, FaUsers, FaCodepen, FaIdeal, FaFreeCodeCamp, FaBolt, FaQuestion, FaGoodreads,
  FaNewspaper, FaCloudSunRain, FaBell, FaLaptopHouse, FaCircle,
  FaRegBell,
  FaMap,
  FaKey,
  FaPaperPlane,
  FaList,
  FaComment,
} from "react-icons/fa";
import './TheLayout.css';
import queryRaised from '../assets/image/queryRaise.png';

let allowedItems = [
  {
    permission: "dashboard.view",
    label: "Dashboard",
    _tag: "CSidebarNavItem",
    name: (
      <div className="sideBarNav">
        <FaHome />
        <span color="primary">Dashboard</span>
      </div>
    ),
    to: "/",
    children: [
      {
        permission: "dashboard.view",
        label: "Dashboard",
        _tag: "CSidebarNavItem",
        to: "/",
        name: (
          <div className="sideBarNav">
            <FaLaptopHouse />
            <span color="primary">EVS Dashboard</span>
          </div>
        ),
      }
    ]
  },
  {
    permission: "school.management",
    label: "School Management",
    _tag: "CSidebarNavItem",
    name: (
      <div className="sideBarNav">
        <FaUniversity />
        <span>School Management</span>
      </div>
    ),
    to: "/organization/list",
    children: [
      {
        permission: "student.list.view",
        label: "Student List",
        _tag: "CSidebarNavItem",
        to: "/student/list",
        name: (
          <div className="sideBarNav">
            <FaUserCog />
            <span>Manage Student</span>
          </div>
        ),
      },
      {
        permission: "class.teacher.view",
        label: "Class Teacher",
        _tag: "CSidebarNavItem",
        to: "/class-teacher/list",
        name: (
          <div className="sideBarNav">
            <FaUserCog />
            <span>Manage Class Teacher</span>
          </div>
        ),
      },
      {
        permission: "student.promote",
        label: "Promote Students",
        _tag: "CSidebarNavItem",
        to: "/student/promote",
        name: (
          <div className="sideBarNav">
            <FaDribbble />
            <span>Promote Students</span>
          </div>
        ),
      },
      {
        permission: "student.subject.mapping.view",
        label: "Evaluation",
        _tag: "CSidebarNavItem",
        to: "/student/subject/mapping/list",
        name: (
          <div className="sideBarNav">
            <FaListAlt />
            <span>Student Subject Mapping</span>
          </div>
        ),
      },
      // {
      //   permission: "student.subject.mapping.view",
      //   label: "Evaluation",
      //   _tag: "CSidebarNavItem",
      //   to: "/student/subject/enroll",
      //   name: (
      //     <div className="sideBarNav">
      //       <FaListAlt />
      //       <span>Student Subject Enrollment</span>
      //     </div>
      //   ),
      // },
      {
        permission: "teacher.subject.mapping.view",
        label: "Subject Teacher Mapping",
        _tag: "CSidebarNavItem",
        to: "/teacher/list",
        name: (
          <div className="sideBarNav">
            <FaChalkboardTeacher />
            <span>Subject Teacher Mapping</span>
          </div>
        ),
      },
    ]
  },
  {
    permission: "academic.management",
    label: "Academic Management",
    _tag: "CSidebarNavItem",
    name: (
      <div className="sideBarNav">
        <FaIdeal />
        <span>Academic Management</span>
      </div>
    ),
    to: "/",
    children: [
      {
        permission: "sessions.view",
        label: "Sessions",
        _tag: "CSidebarNavItem",
        name: (
          <div className="sideBarNav">
            <FaClock />
            <span>Sessions</span>
          </div>
        ),
        to: "/session/list",
      },
      {
        permission: "schools.view",
        label: "Schools",
        _tag: "CSidebarNavItem",
        name: (
          <div className="sideBarNav">
            <FaUniversity />
            <span>Schools</span>
          </div>
        ),
        to: "/organization/list",
      },
      {
        permission: "class.view",
        _tag: "CSidebarNavItem",
        label: "Class",
        name: (
          <div className="sideBarNav">
            <FaFolder />
            <span>Class</span>
          </div>
        ),
        to: "/program/list",
      },
      {
        permission: "class.view",
        _tag: "CSidebarNavItem",
        label: "Class",
        name: (
          <div className="sideBarNav">
            <FaFolder />
            <span>Class Session Mapping</span>
          </div>
        ),
        to: "/programs/session/mapping",
      },
      {
        permission: "subjects.view",
        _tag: "CSidebarNavItem",
        label: "Scholastic Subjects",
        name: (
          <div className="sideBarNav">
            <FaBookOpen />
            <span>Scholastic Subjects</span>
          </div>
        ),
        to: "/subject/list",
      },
      {
        permission: "traits.view",
        _tag: "CSidebarNavItem",
        label: "Co-Scholastic Subjects",
        name: (
          <div className="sideBarNav">
            <FaClipboardList />
            <span>Co-Scholastic Subjects</span>
          </div>
        ),
        to: "/trait/list",
      },
    ]
  },
  {
    permission: "exam.management",
    label: "Exams",
    _tag: "CSidebarNavItem",
    to: "/exam/list",
    name: (
      <div className="sideBarNav">
        <FaBook />
        <span>Exam Management</span>
      </div>
    ),
    children: [
      {
        permission: "exam.config.view",
        label: "Exams",
        _tag: "CSidebarNavItem",
        to: "/exam/config/list",
        name: (
          <div className="sideBarNav">
            <FaCalendarAlt />
            <span>Exam Configuration</span>
          </div>
        ),
      },
      {
        permission: "exams.view",
        label: "Exams",
        _tag: "CSidebarNavItem",
        to: "/exam/list",
        name: (
          <div className="sideBarNav">
            <FaCalendarAlt />
            <span>Manage Exam</span>
          </div>
        ),
      },
      {
        permission: "assessment.marking.view",
        label: "Assessment Marking",
        _tag: "CSidebarNavItem",
        to: "/assessment/marking/list",
        name: (
          <div className="sideBarNav">
            <FaMarker />
            <span>Assessment Marking</span>
          </div>
        ),
      },
      {
        permission: "result.view",
        label: "Report Card",
        _tag: "CSidebarNavItem",
        to: "/report/card/manage",
        name: (
          <div className="sideBarNav">
            <FaGraduationCap />
            <span>Report Card</span>
          </div>
        ),
      },
      {
        permission: "publish.result.view",
        label: "Publish Result",
        _tag: "CSidebarNavItem",
        to: "/publish/result/list",
        name:
          <div className="sideBarNav">
            <FaCheckCircle /><span>Publish Report Card</span>
          </div>
      },
    ]
  },
  {
    permission: "admin.management",
    label: "Admin Management",
    _tag: "CSidebarNavItem",
    name: (
      <div className="sideBarNav">
        <FaFreeCodeCamp />
        <span>Admin Management</span>
      </div>
    ),
    to: "/template/list",
    children: [
      {
        permission: "sync.student",
        label: "Sync Students",
        _tag: "CSidebarNavItem",
        to: "/student/sync",
        name: (
          <div className="sideBarNav">
            <FaUsers />
            <span>Sync Students</span>
          </div>
        ),
      },
      {
        permission: "template.view",
        label: "Template",
        _tag: "CSidebarNavItem",
        name: (
          <div className="sideBarNav">
            <FaLaptop />
            <span>Template</span>
          </div>
        ),
        to: "/template/list",
      },
      {
        permission: "assessment.list.view",
        label: "Assessment",
        _tag: "CSidebarNavItem",
        to: "/assessment/list",
        name: (
          <div className="sideBarNav">
            <FaClipboardList />
            <span>Assessment Listing</span>
          </div>
        ),
      },
      {
        permission: "scoreschemes.view",
        label: "Score Shemes",
        _tag: "CSidebarNavItem",
        to: "/score/schemes/list",
        name: (
          <div className="sideBarNav">
            <FaTachometerAlt />
            <span>Score Shemes</span>
          </div>
        ),
      },
      {
        permission: "evaluationtypes.view",
        label: "Evaluation",
        _tag: "CSidebarNavItem",
        to: "/evaluation/type/list",
        name: (
          <div className="sideBarNav">
            <FaListAlt />
            <span>Evaluation</span>
          </div>
        ),
      },
      {
        permission: "users.view",
        label: "User",
        _tag: "CSidebarNavItem",
        to: "/user/list",
        name: (
          <div className="sideBarNav">
            <FaUser />
            <span>Manage User</span>
          </div>
        ),
      },
      {
        permission: "role.view",
        label: "Role",
        _tag: "CSidebarNavItem",
        to: "/role/list",
        name: (
          <div className="sideBarNav">
            <FaUserCog />
            <span>Role</span>
          </div>
        ),
      },
    ]

  },
  {
    permission: "app.management",
    label: "App Management",
    _tag: "CSidebarNavItem",
    to: "/",
    name: (
      <div className="sideBarNav">
        <FaCodepen />
        <span>App Management</span>
      </div>
    ),
    children: [
      {
        permission: "attendance.mapping",
        label: "Attendance",
        _tag: "CSidebarNavItem",
        to: "/attendance/teacher/mapping",
        name: (
          <div className="sideBarNav">
            <FaMap />
            <span>Attendance Teacher Mapping</span>
          </div>
        ),
      },
      {
        permission: "attendance.mapping",
        label: "Attendance",
        _tag: "CSidebarNavItem",
        to: "/attendance/dashboard",
        name: (
          <div className="sideBarNav">
            <FaMap />
            <span>Attendance Dashboard</span>
          </div>
        ),
      },
      {
        permission: "attendance.view",
        label: "Attendance",
        _tag: "CSidebarNavItem",
        to: "/attendance/list",
        name: (
          <div className="sideBarNav">
            <FaCalendarAlt />
            <span>Attendance</span>
          </div>
        ),
      },
      {
        permission: "activation.key.generate",
        label: "Generate Activation Key",
        _tag: "CSidebarNavItem",
        to: "/generate/activation/key",
        name: (
          <div className="sideBarNav">
            <FaKey />
            <span>Generate Activation Key</span>
          </div>
        ),
      },
      {
        permission: "leave.view",
        label: "Leave",
        _tag: "CSidebarNavItem",
        to: "/leave/list",
        name: (
          <div className="sideBarNav">
            <FaBolt />
            <span>Leave</span>
          </div>
        ),
      },
      {
        permission: "circular.view",
        label: "Circular",
        _tag: "CSidebarNavItem",
        to: "/circular/list",
        name: (
          <div className="sideBarNav">
            <FaCircle />
            <span>Circular</span>
          </div>
        ),
      },
      {
        permission: "word_of_the_day.view",
        label: "Word Of The Day",
        _tag: "CSidebarNavItem",
        to: "/word-of-the-day",
        name: (
          <div className="sideBarNav">
            <FaGoodreads />
            <span>Word of The Day</span>
          </div>
        ),
      },
      {
        permission: "event.calendar.view",
        label: "Event",
        _tag: "CSidebarNavItem",
        to: "/event/list",
        name: (
          <div className="sideBarNav">
            <FaChalkboardTeacher />
            <span>Event</span>
          </div>
        ),
      },
      {
        permission: "time_table.view",
        label: "Time Table",
        _tag: "CSidebarNavItem",
        to: "/upload/timetable/list",
        name: (
          <div className="sideBarNav">
            <FaBell />
            <span>Time Table</span>
          </div>
        ),
      },
      {
        permission: "syllabus.view",
        label: "Syllabus",
        _tag: "CSidebarNavItem",
        to: "/syllabus/list",
        name: (
          <div className="sideBarNav">
            <FaCloudSunRain />
            <span>Syllabus</span>
          </div>
        ),
      },
      {
        permission: "notification.view",
        label: "Notifiaction",
        _tag: "CSidebarNavItem",
        to: "/notification/list",
        name: (
          <div className="sideBarNav">
            <FaRegBell />
            <span>Notifiaction</span>
          </div>
        ),
      },
      {
        permission: "news_letter.view",
        label: "NewsLetter",
        _tag: "CSidebarNavItem",
        to: "/newsletter/list",
        name: (
          <div className="sideBarNav">
            <FaNewspaper />
            <span>News Letter</span>
          </div>
        ),
      },
      {
        permission: "faq.view",
        label: "FAQ",
        _tag: "CSidebarNavItem",
        to: "/faq",
        name: (
          <div className="sideBarNav">
            <FaQuestion />
            <span>FAQ</span>
          </div>
        ),
      },
    ]
  },
  {
    permission: "daily.school.life",
    label: "App Management",
    _tag: "CSidebarNavItem",
    to: "/",
    name: (
      <div className="sideBarNav">
        <FaBookOpen />
        <span>Daily School Life</span>
      </div>
    ),
    children: [
      {
        permission: "teacher.circular.view",
        label: "Circular",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/circular",
        name: (
          <div className="sideBarNav">
            <FaCircle />
            <span>Circular</span>
          </div>
        ),
      },
      {
        permission: "teacher.word_of_the_day.view",
        label: "Word Of The Day",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/word-of-the-day",
        name: (
          <div className="sideBarNav">
            <FaGoodreads />
            <span>Word of The Day</span>
          </div>
        ),
      },
      {
        permission: "teacher.time_table.view",
        label: "Time Table",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/timetable",
        name: (
          <div className="sideBarNav">
            <FaBell />
            <span>Time Table</span>
          </div>
        ),
      },
      {
        permission: "teacher.syllabus.view",
        label: "Syllabus",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/syllabus",
        name: (
          <div className="sideBarNav">
            <FaCloudSunRain />
            <span>Syllabus</span>
          </div>
        ),
      },
      {
        permission: "teacher.notification.view",
        label: "Notifiaction",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/notification",
        name: (
          <div className="sideBarNav">
            <FaRegBell />
            <span>Notifiaction</span>
          </div>
        ),
      },
      {
        permission: "teacher.news_letter.view",
        label: "NewsLetter",
        _tag: "CSidebarNavItem",
        to: "/teacher/view/newsletter",
        name: (
          <div className="sideBarNav">
            <FaNewspaper />
            <span>News Letter</span>
          </div>
        ),
      }
    ]
  },
  {
    permission: "query.view",
    label: "App Management",
    _tag: "CSidebarNavItem",
    to: "/",
    name: (
      <div className="sideBarNav">
        <img src={queryRaised} alt="query" style={{ width: "20px" }} />
        <span className="mx-2">Queries</span>
      </div>
    ),
    children: [
      {
        permission: "query.category.view",
        label: "Queries",
        _tag: "CSidebarNavItem",
        to: "/query/category/list",
        name: (
          <div className="sideBarNav">
            <FaList />
            <span>Query Categories</span>
          </div>
        ),
      },
      {
        permission: "query.view",
        label: "Queries",
        _tag: "CSidebarNavItem",
        to: "/queries",
        name: (
          <div className="sideBarNav">
            <FaPaperPlane />
            <span>Queries</span>
          </div>
        ),
      },
      {
        permission: "visitor.view",
        label: "Visitor",
        _tag: "CSidebarNavItem",
        to: "/visitors",
        name: (
          <div className="sideBarNav">
            <i className="fa fa-person-walking mr-2" aria-hidden="true"
              style={{ marginRight: "10px", fontSize: "15px" }}></i>
            <span>Visitor</span>
          </div>
        ),
      },
    ]
  },
  {
    permission: "session.management",
    label: "Session Management",
    _tag: "CSidebarNavItem",
    to: "/",
    name: (
      <div className="sideBarNav">
        <FaClock />
        <span className="mx-2">Session Management</span>
      </div>
    ),
    children: [
      {
        permission: "session.management",
        label: "Queries",
        _tag: "CSidebarNavItem",
        to: "/sync-data/with-data-lake",
        name: (
          <div className="sideBarNav">
            <FaList />
            <span>Session Management</span>
          </div>
        ),
      },
    ]
  },
  {
    permission: "stakeholder.query.view",
    label: "Feedback Management",
    _tag: "CSidebarNavItem",
    to: "/stackholder-query/list",
    name: (
      <div className="sideBarNav">
        <FaComment />
        <span className="mx-2">Feedback Management</span>
      </div>
    ),
    children: [
      // {
      //   permission: "academic.management",
      //   label: "Queries",
      //   _tag: "CSidebarNavItem",
      //   to: "/stackholder-query/list",
      //   name: (
      //     <div className="sideBarNav">
      //       <FaList />
      //       <span>Queries List</span>
      //     </div>
      //   ),
      // },
    ]
  }
];

const permissions = JSON.parse(localStorage.getItem("permissions"));
const nav = allowedItems && allowedItems.filter((item) => {
  if (item.children) {
    item.children = item.children.filter((child) => {
      return permissions && permissions.includes(child.permission);
    });
  }
  return permissions && permissions.includes(item.permission);
}
);

export const navigation = nav;

