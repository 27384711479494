import React, { useState, useEffect } from "react";
import { FaNewspaper } from "react-icons/fa";
import { tableConstants } from "./NewsLetterTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { deleteNewsLetter, getNewsLetters } from "../../../services/AppManagement/NewsLetterService";

const NewsLetterList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  async function fetchNewsLetter({ selected = 0 }) {
    const session_id = localStorage.getItem('session_id');
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      const page = selected + 1;
      const response = await getNewsLetters(session_id, page);
      if (response.status === 200) {
        setLastPage(response.data.data.last_page);
        setData(response.data.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchNewsLetter({ selected: 0 });
  }, []);

  const newsLetterDelete = async (id) => {
    try {
      const response = await deleteNewsLetter(id);
      if (response.status === 200) {
        enqueueSnackbar("NewsLetter Deleted Successfully", { variant: "success" });
        fetchNewsLetter({ selected: 0 });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="News Letter List"
          icon={<FaNewspaper />}
          button={permissions.includes('news_letter.create') &&
            <Link to="/newsletter/create" className="btn btn-primary">Add NewsLetter</Link>}
        />
        <div className="card-body">
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <Table
                    cols={tableConstants(permissions, newsLetterDelete)}
                    data={data}
                    hover={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchNewsLetter(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default NewsLetterList;
