import React, { useState, useEffect } from "react";
import { FaRegBell } from "react-icons/fa";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getNotifications } from "../../../services/AppManagement/NotificationService";
import TeacherViewCard from "../TeacherViewCard";

const TeacherViewNotificationList = () => {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchNotification({ selected = 0 }) {
    try {
      setCurrentPage(selected);
      const page = selected + 1;
      setIsLoader(true);
      const response = await getNotifications(page);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchNotification({ selected: 0 });
  }, []);

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="Student Notification List"
          icon={<FaRegBell />}
          button={''}
        />
        <div className="card-body">
          {isLoader ? <Loader /> :
            <div className="row">
              <div className="min-h-screen flex items-center justify-center bg-black">
              </div>
              {
                data && data.map((item, index) => (
                  <TeacherViewCard
                    title={"IDNUMBER: "}
                    titleValue={item.student.id_number}
                    topic={"NAME: "}
                    topicValue={item.student.first_name}
                    description={item.message}
                    index={index}
                    key={index}
                  />
                ))
              }
              {data && (
                <Pagination
                  totalPage={lastPage}
                  currentPage={currentPage}
                  handlePageChange={(i) => fetchNotification(i)}
                ></Pagination>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default TeacherViewNotificationList;
