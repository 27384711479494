import React from 'react';
import { Form } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const RolePermissionForm = ({ handleSubmit, handleChange, selectAllPermissionHandler,
  permissionHandler, formData }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <Input
            label="Role Name"
            type="text"
            name="name"
            placeholder="Enter role name"
            className={"mb-3"}
            value={formData.name}
            onChangeHandler={handleChange}
            required={true}
          />
        </div>
      </div>
      <Switch
        value="1"
        name="select-all"
        id="select-all"
        checked={formData.permissions.length === formData.permissionData.length}
        onChange={(event) => selectAllPermissionHandler(event)}
        label="Select all"
      />
      <div className="row">
        {formData.permissionData && formData.permissionData.map((item, idx) => (
          <div className="col-md-3 mt-2" key={idx}>
            <Switch
              value={item}
              name={item}
              id={item}
              checked={formData.permissions && formData.permissions.includes(item)}
              onChange={(event) => permissionHandler(event, item)}
              label={item}
            />
          </div>
        ))}
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <div className="">
          <Button variant="success" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default RolePermissionForm;
