import React, { useState, useEffect } from "react";
import { getVisitors } from "../../services/VisitorSevice";
import Table from "../../components/Table/Table";
import CardHeader from "../../components/Card/CardHeader";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { tableConstants } from "./VistitorTableConstant";
import { updateVisitorCheckOut, getGatePass } from "../../services/VisitorSevice";
import VisitorPass from "./VisitorPass";

const VisitorList = () => {
  const [visitors, setVisitors] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const [passVisible, setPassVisible] = useState(false);
  const [passMemberID, setPassMemberID] = useState(null);

  useEffect(() => {
    fetchVisitors({ selected: 0 });
  }, []);

  const fetchVisitors = async ({ selected }) => {
    try {
      setIsLoader(true);
      let page = selected + 1;
      setCurrentPage(page);
      const response = await getVisitors(page);
      if (response.status === 200) {
        setVisitors(response.data.data.data);
        setLastPage(response.data.data.last_page);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  };

  const checkOutHandler = async (id) => {
    try {
      const response = await updateVisitorCheckOut(id);
      if (response.status === 200) {
        fetchVisitors({ selected: currentPage - 1 });
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const downloadPass = async (id) => {
    setPassMemberID(id);
    setPassVisible(true);
  };

  const downloadPassPdfFormat = async (id) => {
    try {
      const response = await getGatePass(id);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "VisitorPass.pdf";
      link.click();
    } catch (error) {
      console.error("Error generating pass:", error);
    }
  }

  return (
    <React.Fragment>
      {isLoader ? (<Loader />) :
        (<div className="card">
          <CardHeader
            title="Visitors"
            icon={
              <i className="fa fa-person-walking" aria-hidden="true"
                style={{ color: "#6259ca", fontSize: "15px" }}></i>}
            button={permissions.includes("visitor.create") && (
              <Link className='btn btn-primary' to="/visitor/create">
                Add Visitor
              </Link>
            )}
          />
          <div className="card-body evaluating-table">
            {passVisible && <VisitorPass visitorId={passMemberID} />}
            <Table
              cols={tableConstants(checkOutHandler, downloadPass, downloadPassPdfFormat)}
              data={visitors}
              hoverable={true}
              striped={true}
            />
            <Pagination
              totalPage={lastPage}
              currentPage={currentPage - 1}
              handlePageChange={(i) => fetchVisitors(i)}
            ></Pagination>
          </div>
        </div>
        )}
    </React.Fragment>
  );
};

export default VisitorList;
