import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const TeacherViewCard = ({ title = '', titleValue = '', topic = '', topicValue = '',
  description = '', programs = '', index, date, dateValue, colSize = "6", expandedIdx = [0, 1] }) => {
  const [expandedIndices, setExpandedIndices] = useState(expandedIdx); // First and second card expanded by default

  const toggleExpand = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className={`col-md-${colSize} ${index !== 0 || index !== 1 ? "mt-3" : ""}`}>
      <div className="card" style={{ border: "2px solid #ccc" }}>
        <div className="card-header d-flex justify-content-between cursor-pointer"
          onClick={() => toggleExpand(index)}>
          <span style={{ textTransform: "uppercase", fontWeight: "600" }}>
            <span
              style={{ fontSize: "17px", fontWeight: "600", textTransform: "uppercase" }}>
              {title}
            </span>
            {titleValue}
          </span>
          {expandedIndices.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        <div className="card-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{
              textTransform: "uppercase", fontWeight: "600"
            }}>
              <span style={{ color: "blue", fontSize: "16px", fontWeight: "500", textTransform: "uppercase !important" }}>{topic}</span>
              {topicValue}
            </span>
            <span>
              <span style={{ color: "blue", fontSize: "16px", fontWeight: "500", textTransform: "uppercase" }}>{date}</span>
              {dateValue}
            </span>
          </div>
          {expandedIndices.includes(index) && (
            <React.Fragment>
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              {programs &&
                <div className="row mt-2">
                  <h6 style={{ color: "blue" }}>CLASSES: </h6>
                  {programs.map((programObj, idx) => (
                    <div
                      key={idx}
                      className="col-md-2"
                      style={{
                        padding: "5px",
                        background: "#ccc",
                        borderRadius: "3px",
                        margin: "5px",
                        marginTop: "7px",
                      }}
                    >
                      {programObj.program ? programObj.program.name : ""}
                    </div>
                  ))}
                </div>
              }
            </React.Fragment>)}
        </div>
      </div>
    </div>
  );
};

export default TeacherViewCard;
