import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getTeacherSubjectMapping = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.TEACHER.GET_TEACHER_SUBJECT_MAPPING}/${id}`, { params: { session_id } });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createTeacherSubjectMapping = async (id, data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.TEACHER.CREATE_TEACHER_SUBJECT_MAPPING}/${id}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateTeacherSubjectMapping = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TEACHER.UPDATE_TEACHER_SUBJECT_MAPPING}/${id}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateTeacherPassword = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TEACHER.UPDATE_PASSWORD}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const bulkUploadTeachersClassSubject = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.TEACHER.TEACHER_SUBJECT_MAPPING_BULK_UPLOAD, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateTeacherTimeTableStatus = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TEACHER.UPDATE_TEACHER_TIMETABLE_STATUS}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}
