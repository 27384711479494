import React from 'react';
import { Form } from 'react-bootstrap';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';

const VisitorForm = ({ handleChange, handleSubmit, name, phone, meeting_with, purpose, category_id }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Input
            label="name"
            type="text"
            name="name"
            onChange={handleChange}
            placeholder="Enter name"
            value={name}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="phone"
            type="text"
            name="phone"
            onChange={handleChange}
            placeholder="Enter phone"
            value={phone}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Purpose"
            type="text"
            name="purpose"
            onChange={handleChange}
            placeholder="Enter purpose"
            value={purpose}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Meeting With"
            type="text"
            name="meeting_with"
            onChange={handleChange}
            placeholder="Enter name"
            value={meeting_with}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Select
            label={'Category'}
            name="category_id"
            value={category_id}
            onChangeHandler={handleChange}
            options={[
              { id: 1, name: 'Parent' },
              { id: 2, name: 'Vendor' },
              { id: 3, name: 'Guest' }]
            }
            placeHolder="Select Category"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <Button variant="success" type="submit">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default VisitorForm;
