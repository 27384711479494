import React, { useEffect, useState } from 'react';
import { fetchPrograms } from '../../redux/program/programActions';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import { tableConstants } from './StudentSubjectMappingTableConst';
import { tableConstants as studentTableConstants } from './StudentCountSubjectWiseTableConst';
import StudentSubjectFilter from './StudentSubjectFilter';
import { getStudentSubjectMappingList, getStudentCountSubjectWise } from '../../services/StudentSubjectMappingService';
import { getClassWiseSubjects } from '../../services/SubjectServices';
import { enqueueSnackbar } from 'notistack';

const StudentSubjectMappingList = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState("");
  const [subject_id, setSubjectId] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [student_count, setStudentCount] = useState("1");
  const [loader, setLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);

  const fetchData = async () => {
    let session_id = localStorage.getItem("session_id");
    if(!program_id) enqueueSnackbar("Please select class", { variant: 'error' });
    try {
      setLoader(true);
      setData([]);
      if (student_count === "1") {
        let response = await getStudentCountSubjectWise(program_id);
        console.log("response", response);
        if (response.status === 200) {
          setData(response.data.data);
        }
      }
      else {
        let response = await getStudentSubjectMappingList(session_id, program_id, subject_id);
        if (response.status === 200) {
          setData(response.data.data);
        }
      }
    }
    catch (error) {
      console.log("Error", error);
    }
    finally {
      setLoader(false);
    }
  }

  const getSubjectList = async (programId) => {
    try {
      const res = await getClassWiseSubjects(programId);
      if (res.status !== 200) {
        return;
      }
      const subjectData = res.data.data;
      setSubjectList(subjectData);
    } catch (error) {
      console.log(error);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
      getSubjectList(e.target.value);
    }
    if (name === 'subject_id') {
      setSubjectId(value);
    }
  }

  useEffect(() => {
    dispatch(fetchPrograms());
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="main-title-page page-title">
            <span>Student Subject Mapping List</span>
          </div>
          <div className="">
            {permissions.includes("student.subject.mapping.update") &&
              <Link className="btn btn-secondary mx-2" to="/student/subject/mapping/update">
                Update Mapping
              </Link>
            }
            {
              permissions.includes("student.subject.mapping.create") &&
              <Link className="btn btn-success" to="/student/subject/mapping/create">
                Add Mapping
              </Link>}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-md-6">
            <input
              className="form-check-input"
              type="radio"
              name="student_count"
              id="radio"
              value={student_count}
              checked={student_count === "1"}
              onChange={() => {setStudentCount("1");setData([])}}
            />
            <label className="form-check-label" htmlFor="radio">
              Subject wise student count
            </label>
          </div>
          <div className="col-md-6">
            <input
              className="form-check-input"
              type="radio"
              name="student_count"
              id="radio1"
              value={student_count}
              checked={student_count === "2"}
              onChange={() => {setStudentCount("2");setData([])}}
            />
            <label className="form-check-label" htmlFor="radio1">
              Subject wise student list
            </label>
          </div>
        </div>
        <StudentSubjectFilter
          student_count={student_count}
          programData={programData}
          program_id={program_id}
          subject_id={subject_id}
          subjectList={subjectList}
          changeHandler={changeHandler}
          fetchData={fetchData}
        />
        <div className=''>
          {loader ? <div className="loader">Loading...</div> :
            <div className='evaluating-table mt-2'>
              {data && data.length > 0 ?
                student_count === "1" ?
                  <Table
                    cols={studentTableConstants()}
                    data={data}
                    striped={true}
                    hoverable={true}
                  /> :
                  <Table
                    cols={tableConstants()}
                    data={data}
                    striped={true}
                    hoverable={true}
                  /> : 'No data found'}
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default StudentSubjectMappingList;
