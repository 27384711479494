import React from "react";
import Switch from "../../components/Switch/Switch";
import Input from "../../components/Input/Input";
import { FaEdit } from "react-icons/fa";
import { getTermLabel } from "../../utils/helper";

export const tableConstants = (handleInputChange, inputChangeHandler, selectAllExam, idChangeHandler, id, eventData, exam_ids, page = 1) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{50 * (page - 1) + count++}</span>;
      },
    },
    {
      title: 'Exam Header',
      render: rowData => {
        return <span>{rowData.name && rowData.name.name}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {getTermLabel(rowData.term)}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },

    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },

    {
      title: 'Evaluation',
      render: rowData => {
        return <span>{rowData.evaluation_type.name}</span>;
      },
    },
    {
      title: 'Max Score',
      render: rowData => {
        return <span>{
          Number(id) === Number(rowData.id) ?
            <Input
              type="number"
              name="max_score"
              id="max_score"
              value={rowData.max_score}
              onChangeHandler={(e) => inputChangeHandler(e, rowData)}
            />
            : rowData.max_score}</span>;
      },
    },
    {
      title: 'Start',
      render: rowData => {
        return <span>
          {Number(id) === Number(rowData.id) ?
            <Input
              type="date"
              name="start"
              value={rowData.start}
              onChangeHandler={(e) => inputChangeHandler(e, rowData)}
            /> : rowData.start}
        </span>;
      },
    },
    {
      title: 'End',
      render: rowData => {
        return <span>
          {Number(id) === Number(rowData.id) ?
            <Input
              type="date"
              name="end"
              id="end"
              value={rowData.end}
              onChangeHandler={(e) => inputChangeHandler(e, rowData)}
            /> : rowData.end}
        </span>;
      },
    },
    {
      title: () => <Switch
        checked={eventData.length === 0 ? false : eventData.length === exam_ids.length ? true : false}
        name="all"
        id="select-all"
        value="1"
        onChange={e => selectAllExam(e)}
        label={'Select All'}
      />,
      render: rowData => {
        return <div className="form-check form-check-inline">
          <Switch
            name="exam_id"
            id={rowData.id}
            checked={exam_ids.includes(rowData.id.toString())}
            value={rowData.id || ''}
            onChange={e => handleInputChange(e, rowData)}
          />
        </div>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          <FaEdit
            onClick={() => idChangeHandler(rowData.id)}
            color="blue"
            className="cursor-pointer" />
        </span>;
      }
    },
  ];
  return formatedData;
};
