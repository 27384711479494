import React, { useEffect, useState } from 'react';
import Table from "../../components/Table/Table";
import { FaLaptop } from "react-icons/fa";
import { tableConstants } from "./ClassTeacherTableConstant";
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { getClassTeachers, createClassTeacher } from '../../services/ClassTeacherService';
import { getUsers } from '../../services/UserService';
import CardHeader from '../../components/Card/CardHeader';

const ClassTeacherList = () => {
  const [data, setdata] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [program_id, setProgramID] = useState(null);
  const [teacher_id, setTeacherID] = useState(null);
  const [teacher_list, setTeacherList] = useState([]);
  const [loader, setLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const fetchData = async () => {
    try {
      setIsLoader(true);
      const response = await getClassTeachers();
      if (response.status === 200) {
        const data = response.data.data;
        setdata(data);
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      console.error(error);
    }
  };

  const fetchTeacherList = async () => {
    try {
      setIsLoader(true);
      const response = await getUsers("", 1, 200);
      if (response.success) {
        const data = response.data;
        setTeacherList(data);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchData({ selected: 0 });
    fetchTeacherList();
  }, []);

  const programHandler = (rowData) => {
    setProgramID(rowData.program_id);
  }

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    if (id.includes("teacher")) {
      setTeacherID(value);
    }
  }

  const updateClassTeacherData = async () => {
    try {
      setLoader(false);
      const data = { program_id, teacher_id };

      try {
        const response = await createClassTeacher(data);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          fetchData();
          setProgramID(null);
        }
      } catch (error) {
        console.error(error);
        setLoader(true);
      }
    } catch (error) {
      // Handle error
      return error;
    }
  };

  return (
    <React.Fragment>
      {isLoader ? (<Loader />) :
        (<div className="card">
          <CardHeader
            title="Class Teacher List"
            icon={<FaLaptop />}
            button={""}
          />
          <div className="card-body evaluating-table">
            <Table
              cols={tableConstants(updateClassTeacherData, onChangeHandler, programHandler, program_id, teacher_list, permissions)}
              data={data}
              hoverable={true}
              striped={true}
            />
          </div>
        </div>
        )}
    </React.Fragment>
  );
};

export default ClassTeacherList;
