import { useDispatch, useSelector } from "react-redux";
import { fetchSessions } from "../../redux/session/sessionActions";
import { fetchEvents } from "../../redux/event/eventActions";
import React, { useEffect, useState } from "react";
import Select from "../../components/Select/Select";

const Session = () => {
  const [session_id, setSessionId] = useState(localStorage.getItem("session_id"));
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.session.sessions.data);
  const [session_name, setSessionName] = useState("");

  useEffect(() => {
    dispatch(fetchSessions());
  }, []); // Include fetchSessions in the dependency array

  const handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    localStorage.setItem("session_id", value);
    let sessionName = sessionData.find((session) => session.id == value);
    setSessionName(sessionName.name);
    localStorage.setItem("session_name", sessionName.name);

    setSessionId(value);
    if (window.location.href.includes("/event/list")) {
      fetchEvents();
    }
    pathWiseAPIRefresh();
    window.location.reload();
  };
  const pathWiseAPIRefresh = () => {
    if (window.location.href.includes("/")) {
      dispatch(fetchSessions());
    }
    if (window.location.href.includes("/event/list")) {
      dispatch(fetchEvents());
    }
  }

  return (
    <Select
      name="session_id"
      onChangeHandler={(e) => handleChange(e)}
      value={session_id}
      className="form-select"
      placeHolder="Select Session"
      options={sessionData}
    />
  );
};
export default Session;

