import React from 'react';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

const QueryFilter = (
  {
    status,
    setStatus,
    category_id,
    setCategoryId,
    date,
    setDate,
    categories,
    searchHandler
  }
) => {
  return (
    <React.Fragment>
      <div className="col-md-3">
        <Select
          name="status"
          id="status"
          value={status}
          onChangeHandler={(e) => setStatus(e.target.value)}
          options={[
            { id: "", name: "Select Status" },
            { id: "open", name: "Open" },
            { id: "in_progress", name: "In Progress" },
            { id: "closed", name: "Closed" },
          ]}
        />
      </div>
      <div className="col-md-3">
        <Select
          name="category_id"
          id="category_id"
          value={category_id}
          placeHolder={"Select Category"}
          onChangeHandler={(e) => setCategoryId(e.target.value)}
          options={categories}
        />
      </div>
      <div className="col-md-3">
        <Input
          type="date"
          name="date"
          id="date"
          value={date}
          onChangeHandler={(e) => setDate(e.target.value)}
        />
      </div>
      <div className="col-md-2">
        <button
          className="btn btn-primary"
          onClick={searchHandler}
        >
          Search
        </button>
      </div>
    </React.Fragment>
  );
};

export default QueryFilter;
