import React from "react";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const tableConstants = (generateActivationKeySingleStudent, isLoader, id_number) => {
  let count = 1;
  let formatedData = [
    {
      title: 'Sr',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'RollNo',
      render: (rowData) => {
        return <span>{rowData.roll_no}</span>;
      },
    },
    {
      title: 'Admission_NO',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    },
    {
      title: 'First_Name',
      render: rowData => {
        return <span>{rowData.first_name}</span>;
      },
    },
    {
      title: 'Last_Name',
      render: rowData => {
        return <span>{rowData.last_name}</span>;
      },
    },
    {
      title:()=><span style={{minWidth:"100px !important"}}>Class</span>,
      render: rowData => {
        return <span style={{minWidth:"100px !important"}}>{rowData.programs && rowData.programs.name}</span>;
      },
    },
    {
      title: 'Date_of_Birth',
      render: rowData => {
        return <span>{rowData.dob}</span>;
      },
    },
    {
      title: 'Mother_Name',
      render: rowData => {
        return <span>{`${rowData.mother_name}`}</span>;
      },
    },
    {
      title: 'Father_Name',
      render: rowData => {
        return <span>{`${rowData.father_name}`}</span>;
      },
    },
    {
      title: 'Contact_No',
      render: rowData => {
        return <span>{rowData.phone1}</span>;
      },
    },
    {
      title: 'House_Name',
      render: rowData => {
        return <span>{rowData.house_name}</span>;
      },
    },
    {
      title: 'ActivationKey',
      render: rowData => {
        return <span>{rowData.id_number === id_number ? isLoader && <div className="spinner-border text-primary" /> : ""}{rowData.activation_key}</span>;
      },
    },
    {
      title: 'Generate_Key',
      render: rowData => {
        return (
          <span>
            <FontAwesomeIcon
              icon={faKey}
              color="#321fdb"
              style={{
                cursor: "pointer"
              }}
              onClick={() => { generateActivationKeySingleStudent(rowData) }}
            />
          </span>
        );
      }
    }
  ];
  return formatedData;
};
