import React, { useState, useEffect, useCallback } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { tableConstants } from "./QueryTableConstant";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table/Table";
import { enqueueSnackbar } from "notistack";
import { getQueries, updateQueryStatus } from "../../services/QueryService";
import { getQueryCategories } from "../../services/QueryCategoryService";
import { debounce } from "lodash"; // Import debounce from lodash
import { useNavigate } from "react-router-dom";
import QueryFilter from "./QueryFilter";

const QueryList = () => {
  const [name, setName] = useState("");
  const [permissions] = useState(JSON.parse(localStorage.getItem("permissions")));
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [date, setDate] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const searchHandler = (e) => {
    e.preventDefault();
    fetchQueryList(1);
  };

  const fetchQueryList = async (page) => {
    try {
      setLoader(true);
      setCurrentPage(page);

      const params = {
        page: page,
      };

      if (status) params.status = status;
      if (category_id) params.category_id = category_id;
      if (date) params.date = date;

      const response = await getQueries(params);
      console.log("QUERIES", response);
      if (response.success) {
        setData(response.data);
        setTotalPage(response.last_page);
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getQueryCategories();
      if (response.success) {
        setCategories(response.data);
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value, page) => {
      fetchQueryList(page);
    }, 300), // 300ms debounce delay
    []
  );

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length > 2 || value.length === 0)
      debouncedSearch(value, 1); // Call debounced function
  };

  useEffect(() => {
    fetchCategories();
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    } else {
      fetchQueryList(1);
    }
  }, []);

  const statusHandler = async (id, status) => {
    try {
      const response = await updateQueryStatus(id, status);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        fetchQueryList(1);
      } else {
        enqueueSnackbar(response.data.message.error, { variant: "error" });
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (i) => {
    const page = i.selected + 1;
    fetchQueryList(page);
  }

  const redirectQueryDetails = (id) => {
    navigate(`/queries/${id}`);
  }

  return (
    <div className="card">
      {loader ? (<Loader />) :
        (<div className="roleUser-list">
          <div className="card-header">
            <div className="row">
              <div className="col-md-1 main-title-page page-title">
                <FaPaperPlane /> Queries
              </div>
              <QueryFilter
                status={status}
                setStatus={setStatus}
                category_id={category_id}
                setCategoryId={setCategoryId}
                date={date}
                setDate={setDate}
                categories={categories}
                searchHandler={searchHandler}
              />
            </div>
          </div>
          <div className="card-body evaluating-table">
            {data && data.length ? (
              <Table
                cols={tableConstants(permissions, redirectQueryDetails, statusHandler, currentPage)}
                data={data}
                hoverable={true}
                striped={true}
              />
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center vh-10">
                <div>
                  <p className="mt-3">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        )}
      {data && data.length ? (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage - 1}
          handlePageChange={(i) => handlePageChange(i)}
        />
      ) : null}
    </div>
  );
};

export default QueryList;
