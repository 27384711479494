import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';

const ProgramSessionMappingForm = ({ onSubmit, programList, onInputChange, onSwitchChange, programIds, loader }) => {
  return (
    <Form onSubmit={onSubmit}>
      {loader && <div className="loader"><Loader /></div>}
      <div className="row">
        <div className="col-md-12">
          <Form.Label>Available for classes</Form.Label>
          <Switch
            label='Select All'
            value={'1'}
            checked={programIds.length === programList.length}
            name='all'
            id='select-all'
            onChange={onSwitchChange}
          />
          <div className="row">
            <div className="col-md-12">
              {programList && programList.map((program, idx) => (
                <div className="form-check form-check-inline col-md-2" key={idx}>
                  <Switch
                    label={program.name}
                    id={program.id}
                    name="programIds"
                    value={program.id}
                    checked={programIds.includes(program.id.toString())}
                    onChange={onInputChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-12 justify-content-end d-flex">
          <div className="mt-2">
            <Button
              variant="success"
              disabled={loader}
              type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProgramSessionMappingForm;
