

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./Query.css";
import { FaChevronDown, FaChevronUp, FaPaperPlane } from 'react-icons/fa';

const QueryReplyForm = (
  {
    query, query_id, reply, handleSubmit, handleChange, queryHandler
    // queryReplyHandler
  }
) => {
  // Track the open state of replies
  const [openReply, setOpenReply] = useState(0); // Default open the first reply

  // Toggle the reply open/close state
  const toggleReply = (index) => {
    setOpenReply(openReply === index ? -1 : index); // If same index clicked, close it
  };

  return (
    <div>
      {/* query details */}
      <div className='d-flex justify-content-between'>
        <span className='query-type text-capitalize'> {query.category && query.category.name}</span>
      </div>
      <div className="query-container">
        <div className="query-details-container">
          <div className='profile-details'>
            <img src={query.raised_by && query.raised_by.profile_image} className='profile-image' />
            <span className='profile-name'>
              {query.raised_by && query.raised_by.first_name + " " + query.raised_by.last_name}
            </span>
            {console.log("QUERY", query)}
            {!query.query_flag &&
              <input type='button' className='reply-btn' value={"Reply"} onClick={() => queryHandler(query.id)} />
            }
          </div>
          <div className='query-status'>
            <span>{query.created_at}</span>
            <span className={`${query.status == "closed" ? "bg-danger" : "bg-success"} text-uppercase badge mx-2`}>{query.status && query.status}</span>
          </div>
        </div>
        <div className='query-message'>
          <span>{query.message}</span>
        </div>
      </div>

      {/* Reply part */}
      {query.replies && query.replies.map((reply, index) => (
        <div key={index} className="query-container">
          <div className={`query-details-container mt-2 card p-2 ${openReply === index ? 'open' : 'closed'}`}>
            <div className='profile-details cursor-pointer' onClick={() => toggleReply(index)}>
              <img src={reply.replied_by && reply.replied_by.profile_image} className='replied-user-profile-image' />
              <span className='profile-name'>
                {reply.replied_by && reply.replied_by.name}
              </span>
              <span className=''>
                REPLIED ON: {reply.created_at}
              </span>
              {openReply === index ?
                <FaChevronUp /> : <FaChevronDown />
              }
            </div>
            {openReply === index && ( // Show reply content only when open
              <div className='replied-message'>
                <div dangerouslySetInnerHTML={{ __html: reply.reply }} />
              </div>
            )}
          </div>
        </div>
      ))}
      {((query.id == query_id) || (query.query_flag)) &&
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 mt-2">
              <Form.Group>
                <Form.Label>Reply</Form.Label>
              </Form.Group>
              <CKEditor
                editor={ClassicEditor}
                data={reply}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChange({ target: { name: 'reply', value: data } });
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button variant="secondary" type="submit">
              <FaPaperPlane /> Send
            </Button>
          </div>
        </Form>
      }
    </div>
  );
};

export default QueryReplyForm;
