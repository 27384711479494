import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Reusable Table Component with Sorting
 * @param {Object[]} cols - Array of column definitions { title: string, render: function(rowData), key: string }.
 * @param {Object[]} data - Array of data objects to render rows.
 * @param {boolean} bordered - Whether the table should have borders.
 * @param {boolean} hoverable - Whether rows should have hover effects.
 * @param {boolean} striped - Whether rows should alternate background colors.
 * @param {boolean} isDark - Whether the table should have a dark theme.
 */
const Table = ({ cols, data, bordered, hoverable, striped, isDark }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortedData, setSortedData] = useState(data);

  const handleSort = (key) => {
    console.log("Sorting by", key);
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key, direction });

    const sorted = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  return (
    <div className="table-responsive" style={{ overflow: "auto" }}>
      <table
        className={`table ${bordered ? "table-bordered" : "table-borderless"}
        ${hoverable ? "table-hover" : ""}
        ${striped ? "table-striped" : ""}
        ${isDark ? "table-dark" : ""}`}
      >
        <thead>
          <tr>
            {cols.map((headerItem, index) => (
              <th
                key={index}
                className="text-uppercase"
                style={{ cursor: "pointer" }}
                onClick={() => headerItem.key && handleSort(headerItem.key)}
              >
                {typeof headerItem.title === "string"
                  ? headerItem.title
                  : headerItem.title()}
                {sortConfig.key === headerItem.key &&
                  (sortConfig.direction === "ascending" ? " 🔼" : " 🔽")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {cols.map((col, colIndex) => (
                <td key={colIndex}>{col.render(item)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      render: PropTypes.func.isRequired,
      key: PropTypes.string, // Used for sorting
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  bordered: PropTypes.bool,
  hoverable: PropTypes.bool,
  striped: PropTypes.bool,
  isDark: PropTypes.bool,
};

Table.defaultProps = {
  bordered: true,
  hoverable: false,
  striped: false,
  isDark: false,
};

export default Table;
